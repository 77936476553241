import React from 'react';
import {IntlProvider, addLocaleData} from "react-intl";

/******************************* React 다국어 처리 시작 *******************************/
import ko from 'react-intl/locale-data/ko';         // 한국어
import en from 'react-intl/locale-data/en';         // 영어
import zh from 'react-intl/locale-data/zh';         // 중국어
import ja from 'react-intl/locale-data/ja';         // 일본어

import message_ko from '../locale/message_ko';      // 한국어
import message_en from '../locale/message_en';      // 영어
import message_zh from '../locale/message_zh';      // 중국어
import message_jp from '../locale/message_jp';      // 일본어
import {getSessionInfo, nullToBlank} from "../utils/commUtils";       // 중국어

addLocaleData([...ko, ...en, ...zh, ...ja]);
/******************************* React 다국어 처리 종료 *******************************/

const IntlContext = React.createContext();
let selectLang;

const selectLangFunc = () =>{
    return selectLang;
}

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);

        this.switchToKorean = () => {
            if(nullToBlank(document.getElementById("filterArea")) !== ""){
                document.getElementById('filterArea').removeAttribute('class');
                document.getElementById('filterArea').classList.add('ko');
            }

            selectLang = "ko";
            this.setState({locale: "ko", messages: message_ko, langIconCode: "KR", localeName: "한국어"});
        }

        this.switchToEnglish = () => {
            if(nullToBlank(document.getElementById("filterArea")) !== ""){
                document.getElementById('filterArea').removeAttribute('class');
                document.getElementById('filterArea').classList.add('en')
            }

            selectLang = "en";
            this.setState({locale: "en", messages: message_en, langIconCode: "US", localeName: "English"});
        }

        this.switchToChinese = () => {
            if(nullToBlank(document.getElementById("filterArea")) !== ""){
                document.getElementById('filterArea').removeAttribute('class');
                document.getElementById('filterArea').classList.add('zh');
            }

            selectLang = "zh";
            this.setState({locale: "zh", messages: message_zh, langIconCode : "CN", localeName: "中國語" });
        }

        this.switchToJapanese = () => {
            if(nullToBlank(document.getElementById("filterArea")) !== ""){
                document.getElementById('filterArea').removeAttribute('class');
                document.getElementById('filterArea').classList.add('ja');
            }

            selectLang = "ja";
            this.setState({locale: "ja", messages: message_jp, langIconCode : "JP", localeName: "日本語" });
        }

        this.getLangInfomation = () => {
            return this.state;
        }

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)
        const sessionUser = getSessionInfo();
        let thisLocle = "ko";
        let thisLangIconCode = "KR";
        let thisLocaleName = "한국어";
        let thisMessages = message_ko;

        if(nullToBlank(sessionUser) != ""){
            switch (sessionUser.SS_USER_LANG_CODE) {
                case 'KOR' :
                    thisLocle = "ko";
                    thisLangIconCode = "KR";
                    thisLocaleName = "한국어";
                    thisMessages = message_ko;
                    break;
                case 'ENG' :
                    thisLocle = "en";
                    thisLangIconCode = "US";
                    thisLocaleName = "English";
                    thisMessages = message_en;
                    break;
                case 'CHI' :
                    thisLocle = "zh";
                    thisLangIconCode = "CN";
                    thisLocaleName = "中國語";
                    thisMessages = message_zh;
                    break;
                case 'JPN' :
                    thisLocle = "ja";
                    thisLangIconCode = "JP";
                    thisLocaleName = "日本語";
                    thisMessages = message_jp;
                    break;
                default :
                    break;
            }

            if(nullToBlank(document.getElementById("filterArea")) !== ""){
                document.getElementById('filterArea').removeAttribute('class');
                document.getElementById('filterArea').classList.add(thisLocle);
            }
        }

        this.state = {
            locale              : thisLocle,
            langIconCode        : thisLangIconCode,
            localeName          : thisLocaleName,
            messages            : thisMessages,
            switchToKorean      : this.switchToKorean,
            switchToEnglish     : this.switchToEnglish,
            switchToChinese     : this.switchToChinese,
            switchToJapanese    : this.switchToJapanese,
            getLangInfomation   : this.getLangInfomation,
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        selectLang = this.state.locale;

        return (
            <IntlContext.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale={locale}
                >
                    {children}
                </IntlProvider>
            </IntlContext.Provider>
        );
    }
}

export { IntlProviderWrapper, IntlContext, selectLangFunc};
