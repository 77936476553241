export const initialState = {
    isSidebarOpened: false,
};
export const TOGGLE_SIDEBAR = "Layout/TOGGLE_SIDEBAR";

export const toggleTypeObject = {type: TOGGLE_SIDEBAR};

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR,
})

const navigatorState =  (state = initialState, action) => {
    switch (action.type){
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpened: !state.isSidebarOpened,
            };
        default :
            return state;
    }
}

export default navigatorState;