import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

import MetisMenu from 'react-metismenu';

import {connect} from "react-redux";
import {MenuSetting} from "./PagesItem";
import {postAxios} from "../../utils/commAxios";
import {getSessionInfo, multiLangMessage, nullToBlank} from "../../utils/commUtils";
import {MENU_CHANGE_TIME} from "../../reducers/propertyState";

const SYSTEM_MENU_SELECT = "PG_ZZ03_0100.PR_SYSTEM_MENU_LIST_SELECT";

class Nav extends Component {
    constructor(props){
        super(props);

        this.state = {
            MenuItem:[],
            MenuList:{key: 101, menuContent: 'MenuNav'},
            //mouted : component가 mount 되었는지 여부 -> 에러 처리
            mounted: false
        }
    }

    intializeMenu(){
        if(nullToBlank(getSessionInfo()) != ""){
            const dataString = {
                'PROCEDURE_OR_TABLE_NAME'   : SYSTEM_MENU_SELECT
            }

            postAxios({
                    method: 'POST',
                    url: "/comm/jsonProcess_Procedure"
                },
                dataString,
                (response) => {
                    let menuList = response.data.resultData.V_OUT_JAVA_CURSOR;
                    let MenuItem = MenuSetting(menuList)

                    this.setState({
                        MenuItem : MenuItem,
                        MenuList : {key: 401, menuContent: 'MenuNav'},
                        mounted: true
                    })
                },
                (e)=>{console.log("에러 "+ e)
                }
             );
        }
    }
    // componentWillMount(){
    //     this.intializeMenu();
    // }

    componentDidMount(){
        this.intializeMenu();
    }

    componentWillReceiveProps(nextProps){
        if(nullToBlank(nextProps.menuChangeTime) != ""){
            this.intializeMenu();

            nextProps.setMenuChangeTime();
        }
    }

    componentWillUnmount(){
        this.setState({ mounted: false });
    }

    render() {
        const menuListSetting = (menu, index) => {
            if(this.state.MenuItem.length){
                return (
                    <>
                        <h5 className="app-sidebar__heading">{menu.label}</h5>
                        <MetisMenu content={menu.content}
                                   activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                                   classNameStateIcon="pe-7s-angle-down"/>
                    </>
                );
            }else{
                return (<></>);
            }
        }

        return (
            <Fragment>
                <div style={{marginTop:'15px'}}>
                    <MetisMenu content={[{
                                    label : multiLangMessage({id:'zz03_0100.title.home', defaultMessage:'홈'}),
                                    to : '#/app/zz/zz03_0100',
                                    icon : 'pe-7s-home',
                               }]}
                               activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                               classNameStateIcon="pe-7s-angle-down"/>
                </div>
                {
                    this.state.MenuItem.map((item, index) => {
                        return (<div key={index}> {menuListSetting(item, index)} </div>)
                    })
                }
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

const mapStateToProps = state => {
    return({
        propertyNo : state.PropertyState.propertyNo,
        propertyName : state.PropertyState.propertyName,
        menuChangeTime : state.PropertyState.menuChangeTime
    });
}

const mapDispatchToProps = dispatch => ({
    setMenuChangeTime: () => dispatch({type:MENU_CHANGE_TIME,menuChangeTime:""})
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
