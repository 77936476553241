/**
 * 파일명       : CancelButton.js
 * 파일 설명	: 프로그램 관리 그리드 취소 버튼
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 이진희 2020.09.23  최초 작성
*/

import React from "react";
import {NORMAL_STATUS, INSERT_STATUS} from "../../../../utils/globalValue";

const CancelButton = props => {
    /**
     * @author JinHui Lee
     * @description Cancel Button Function
    */
    const CancelHandler = () => {
        if (props.data.ROWTYPE === INSERT_STATUS) {
            const gridData = [];

            props.api.updateRowData({remove: [props.data]});
            props.api.forEachNode(node => {
                gridData.push(node.data);
            });

            props.api.setRowData(gridData);
        } else {
            props.node.setDataValue("PAGE_ID", props.data.PAGE_ID_TEMP);
            props.node.setDataValue("PAGE_NAME_MKEY", props.data.PAGE_NAME_MKEY_TEMP);
            props.node.setDataValue("PAGE_TYPE_CN", props.data.PAGE_TYPE_CN_TEMP);
            props.node.setDataValue("PAGE_URL", props.data.PAGE_URL_TEMP);
            props.node.setDataValue("ACTIVE_YN", props.data.ACTIVE_YN_TEMP);
            props.node.data.ROWTYPE = NORMAL_STATUS;
            props.api.redrawRows();

            if (props.callback) props.callback([], true);
        }

        props.api.sizeColumnsToFit();
    }

    return (
        <>
            {props.data.ROWTYPE !== NORMAL_STATUS ? (
                <div className="d-flex align-items-center he-100 justify-content-center">
                    <div
                        className="cancel-ico"
                        onClick={() => CancelHandler()}
                    />
                </div>
            ) : (
                <div className="d-block w-100 text-center"/>
            )}
        </>
    )
}

export {CancelButton};