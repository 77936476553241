import React, { Fragment } from 'react';
import { TextField, OutlinedInput, FilledInput } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MaskedInput,{conformToMask} from 'react-text-mask';

import TimeTextField from './components/TimeTextField'
import { Label, Input as BootInput } from 'reactstrap';

const SimpleTextField = ({classes, ...props}) => {
    let {textposition, readOnly,maxLength, ...options} = props;
    let position = "left";

    if(textposition){
        if(textposition == "right"){
            position = "right";
        }else if(textposition == "center"){
            position = "center";
        }
    }

    if (readOnly === undefined) {
        readOnly = false;
    } 

    return (
        <TextField
            inputProps={{
                readOnly: readOnly,
                style: {textAlign: position},
                maxLength: maxLength
            }}
            {...options}
        />
    )
}

const ReadOnlyTextField = ({classes, ...props}) => {
    const {textPosition, ...options} = props;
    let position = "left";

    if(textPosition){
        if(textPosition == "right"){
            position = "right";
        }else if(textPosition == "center"){
            position = "center";
        }
    }

    return (
        <TextField
            inputProps={{
                readOnly: true,
                style: {textAlign: position}
            }}
            {...props}
        />
    )
}

const DisabledTextField = ({classes, ...props}) => {
    const {textPosition, ...options} = props;
    let position = "left";

    if(textPosition){
        if(textPosition == "right"){
            position = "right";
        }else if(textPosition == "center"){
            position = "center";
        }
    }

    return (
        <TextField
            inputProps={{
                style: {textAlign: position}
            }}
            disabled
            {...props}
        />
    )
}

const MultilineTextField = ({classes, ...props}) => {
    const {textPosition, ...options} = props;
    let position = "left";

    if(textPosition){
        if(textPosition == "right"){
            position = "right";
        }else if(textPosition == "center"){
            position = "center";
        }
    }

    return (
        <TextField
            inputProps={{
                style: {textAlign: position}
            }}
            multiline
            {...props}
        />
    )
}



const ComboTextField = ({classes, ...props}) => {
    const {data,init_value,callbackFunc, ...options} = props;
    const [datas, setDatas] = React.useState({
        comboValue : init_value
    });
    
    const handleChange = (name, callback) => event => {
        setDatas({ ...datas, [name]: event.target.value });

        callback(event);
    };

    return (
        <TextField
            select
            value={datas.comboValue}
            onChange={handleChange('comboValue', callbackFunc)}
            SelectProps={{
                            native: true
                        }}
            {...options}
        >
            {data.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </TextField>
    )
}

const InputAdornmentTextField = ({classes, ...props}) => {
    const {position, unit, ...options} = props;

    return (
        <div>
        { position === 'start' ?

            <TextField
                {...options}
                InputProps={{
                    startAdornment: <InputAdornment position={position}>{unit}</InputAdornment>
                }}
            />
        :
            <TextField
                {...options}
                InputProps={{
                    endAdornment: <InputAdornment position={position}>{unit}</InputAdornment>
                }}
            />
        }
        </div>
    )
}

let MaskFormat = ``;
function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
    
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={MaskFormat}
        placeholderChar={'\u2000'}
        showMask
      />
    );
}

const MaskTextField = ({classes, ...props}) => {
    
    const {textMask,inputLabel,format,className,callbackFunc,required,variant, ...options} = props;
    MaskFormat = format;

    return (
        <FormControl className={className}>
            <InputLabel htmlFor="formatted-text-mask-input" required={required}>{inputLabel}</InputLabel>
            {
                variant === 'outlined' &&
                <OutlinedInput
                    value={props.value}
                    inputComponent={TextMaskCustom}
                    {...options}
                />
            }
            {
                variant === 'filled' &&
                <FilledInput 
                    value={props.value}
                    inputComponent={TextMaskCustom}
                    {...options}
                />
            }
            {
                variant === undefined &&
                <Input
                    value={props.value}
                    inputComponent={TextMaskCustom}
                    {...options}
                />
            }
        </FormControl>
    )
}

const CustomDataMask = (data, modifyFormat) => {
    const conformed = conformToMask(data,modifyFormat,{guide: false});
    
    return conformed.conformedValue;
}

const BootstrapLabelText = (props) => {
    let { label, labelClass, textClass, onChangeFunc, value, required, placeholder, ...etc } = props;
    if (!value) {
        value = "";
    }

    return (
        <Fragment> 
            <Label className={labelClass}>
                {label}
                {
                    required === true &&
                    <div className="badge badge-dot badge-dot-sm badge-danger ml-1">필수항목</div>
                }
            </Label>
            <BootInput type="text" placeholder={placeholder} className={textClass} value={value} onChange={onChangeFunc} {...etc}/>
        </Fragment>
    );
}

export { SimpleTextField, ReadOnlyTextField, DisabledTextField, MultilineTextField, ComboTextField, InputAdornmentTextField, MaskTextField,CustomDataMask ,TimeTextField, BootstrapLabelText}
