/**
 * 파일명       : index.js
 * 파일 설명	: 튜토리얼
 * 시스템 구분	: 공통
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 CMS                최초 작성
*/

import React, {useState} from 'react';
import TutorialStart from './TutorialStart';
import TutorialMain from './TutorialMain';
import TutorialEnd from './TutorialEnd';
import {loadLocalStorage, nullToBlank, removeLocalStorage, saveLocalStorage} from '../../utils/commUtils';
import {useEffect} from 'react';
import {commonRequest} from '../../utils/commAxios';
import { selectLangFunc } from '../../config/ConnectedIntlProvider';

const Tutorial = ({pageId, open, onClose, onActionButton}) => {
    const TUTORIAL_MASTER_SEL = 'PG_ZZ11_0401.PR_TB_ZZ_TUTORIAL_MST_ONE_SEL';
    
    /** @const {Object} 튜토리얼 초기 데이터 포맷 */
    const initTutorialMst = {
        PAGE_NO: '',
        REMARK: '',
        SUBJECT: '',
        TRACE_NO: '',
        TURORIAL_NO: '',
        USE_YN: '',
    };

    const [tutorialMst, setTutorialMst] = useState({
        PAGE_NO: '',
        REMARK: '',
        SUBJECT: '',
        TRACE_NO: '',
        TURORIAL_NO: '',
        USE_YN: '',
    });

    const [tutorialStartOpen, setTutorialStartOpen] = useState(isTutorial(pageId));
    const [tutorialMainOpen, setTutorialMainOpen] = useState(false);
    const [tutorialEndOpen, setTutorialEndOpen] = useState(false);

    /**
     * @author CMS
     * @description 튜토리얼 첫번째 페이지 GO! 버튼 이벤트
    */
    const handleClickNextButtonTwo = () => {
        setTutorialStartOpen(false);
        onClose();
        setTutorialMainOpen(true);
        setTutorialEndOpen(false);
    }

    /**
     * @author CMS
     * @description 
    */
    const handleClickNextButtonThree = stopView => {
        console.log("three")
        setTutorialStartOpen(false);
        setTutorialMainOpen(false);

        if (stopView === true) {
            setTutorialEndOpen(true);
            return;
        }
    }

    /**
     * @author CMS
     * @description 
    */
    const handleClickNextButtonFour = () => {
        console.log("four")
        setTutorialStartOpen(false);
        setTutorialMainOpen(false);
        setTutorialEndOpen(false);
    }

    /**
     * @author CMS
     * @description 
    */
    const handleClickStopView = () => {
        setTutorial(pageId);
    }

    /**
     * @author CMS
     * @description 
    */
    const handleClickAllStopView = () => {
        setTutorialAllPage();
    }

    /**
     * @author CMS
     * @description 
    */
    useEffect(() => {
        if (nullToBlank(pageId) === '') return;

        const form = {
            PROCEDURE_OR_TABLE_NAME: TUTORIAL_MASTER_SEL,
            PAGE_ID: pageId,
            LANG_CN_ATTR_01: selectLangFunc().toUpperCase(),
            USE_YN: 'Y'
        };
          
        commonRequest(result => {
            const tutorialMasterMst = result.data.resultData.V_OUT_JAVA_CURSOR[0];

            if (result.data.resultData.V_OUT_JAVA_CURSOR.length === 0) {  
                onActionButton(true);
                onClose();
                setTutorialMst({...initTutorialMst});

                return; 
            }
        
            setTutorialMst(tutorialMasterMst);
            onActionButton(false);
        }, form);
    }, [pageId, open]);

    return(
        <>
            {tutorialMst.PAGE_NO !== '' && (
                <div>
                    <TutorialStart 
                        open={open ? open : tutorialStartOpen} 
                        onClickNextButton={handleClickNextButtonTwo} 
                    />
                    <TutorialMain 
                        tutorialMst={tutorialMst} 
                        open={tutorialMainOpen} 
                        onClickCloseButton={handleClickNextButtonThree} 
                        pageId={pageId} 
                    />
                    <TutorialEnd 
                        open={tutorialEndOpen} 
                        onClickCloseButton={handleClickNextButtonFour} 
                        onClickStopView={handleClickStopView} 
                        onClickAllStopView={handleClickAllStopView} 
                    />
                </div>
            )}
        </>
    );
}

export default Tutorial;

const isTutorial = pageId => {
    const tutorialJson = loadLocalStorage('LOCAL_STORAGE_TUTORIAL');

    if (tutorialJson === null) return true;

    const tutorial = JSON.parse(tutorialJson);
    const localTutorial = tutorial.findIndex(item => {
        if (item === 'PAGE_ALL') return true;

        return item === pageId;
    });

    if (localTutorial >= 0) return false;
    
    return true;
}

const setTutorial = pageId => {
    if (loadLocalStorage('LOCAL_STORAGE_TUTORIAL') === null) {
        saveLocalStorage('LOCAL_STORAGE_TUTORIAL', JSON.stringify([pageId]));

        return;
    }

    const tutorialJson = JSON.parse(loadLocalStorage('LOCAL_STORAGE_TUTORIAL'));
    const additionalTutorial = [...tutorialJson, pageId];

    saveLocalStorage('LOCAL_STORAGE_TUTORIAL', JSON.stringify(additionalTutorial));
}

const setTutorialAllPage = () => {
    removeLocalStorage('LOCAL_STORAGE_TUTORIAL');
    setTutorial('PAGE_ALL');
}
