import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';

let store;
export default function configureStore() {
  store = createStore(
    combineReducers({
      ...reducers
    }),
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
  )
  return store;
}
export {store}
