import React, {Fragment, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import Ionicon from 'react-ionicons';
import {IntlContext} from "../../../config/ConnectedIntlProvider";
import {HEADER_LANG} from "./Header_lang";
import {getSessionInfo, multiLangReplace, setCookieUserInfo} from "../../../utils/commUtils";
import {withRouter} from "react-router-dom";
import {NOT_READ_NOTICE} from "../../../reducers/propertyState";
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu,
    Nav, Col, Row, Button, NavItem, DropdownItem
} from 'reactstrap';

import city2 from '../../../assets/utils/images/dropdown-header/city2.jpg';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';

import Flag from 'react-flagkit';

import Tabs from 'react-responsive-tabs';

// Dropdown Tabs Content
import ChatExample from './TabsContent/ChatExample';
import TimelineEx from './TabsContent/TimelineExample';

import HeaderNotice from './TabsContent/HeaderNotice';
import HeaderRecent from './TabsContent/HeaderRecent';
import {getCommonCode, postAxios, commonRequest} from "../../../utils/commAxios";

const DELAY_TIME = 1000;
const LANG_CHANGE_PACKAGE = "PG_ZZ03_HEADER.PR_USER_LANG_CHANGE";

const HeaderDots = (props) => {
    const {intl, history, ssUserInfo, setSsUserInfo, setNotReadYn} = props;
    const {formatMessage} = intl;
    const parse = require('html-react-parser');
    const MULTI_LANG = HEADER_LANG(formatMessage);

    const { switchToKorean,  switchToEnglish, switchToChinese, switchToJapanese, getLangInfomation} = React.useContext(IntlContext);

    const [state, setState] = React.useState({active : false});
    const [notiIsOpen, setNotiIsOpen]  = React.useState(false);
    const [notReadNotice, setNotReadNotice] = React.useState(false);
    const [langCode, setLangCode] = React.useState(getLangInfomation());
    const [nowTime, setNowTime] = React.useState("");
    const [tabSelect, setTabSelect] = React.useState(0);
    const [langList, setLangList] = React.useState([
        {code : 'KR', name : MULTI_LANG.HEADER_LANG_KOR},
        {code : 'US', name : MULTI_LANG.HEADER_LANG_ENG},
        {code : 'CN', name : MULTI_LANG.HEADER_LANG_CHI},
        {code : 'JP', name : MULTI_LANG.HEADER_LANG_JPN},
    ]);
    const [comnLancCode, setComnLancCode] = React.useState({});
    const SELECT_NOTICE_VIEW = "PG_ZZ03_HEADER.PR_PM_NOTICE_VIEW_CHANGE";

    const handleClickItem = (traceNo) => {
        setNotiIsOpen(false);

        history.push({
            pathname : '/app/zz/zz03_0210',
            state : {traceNo : traceNo}
        });
    }

    const tabsContent = [
        
        // {
        //     title: 'Recent',
        //     // content: <ChatExample/>
        //     content: <HeaderRecent setIsOpen={setNotiIsOpen} isOpen={notiIsOpen} ssUserInfo={ssUserInfo} onClickItem={handleClickItem}/>
        // },
        
        {
            title: 'Notice',
            //content: <TimelineEx/>
            content: <HeaderNotice setIsOpen={setNotiIsOpen} isOpen={notiIsOpen}/>
        }
    ];

    const searchNoticeView = () => {
        const form = {
            PROCEDURE_OR_TABLE_NAME: SELECT_NOTICE_VIEW,
            DATA_LIST_TB_ZZ_PM_NOTICE: []
        };

        commonRequest(result => {
            const data = result.data.resultData.V_OUT_JAVA_CURSOR;
            const out = result.data.resultData.V_OUT_JSON_VALUE.out_message;
            
            setNotReadYn(out === 'Y' ? true : false);
        }, form);
    }

    useEffect(() => {
        document.title = MULTI_LANG.TITLE_WEB_NAME;
        getCommonCode(
            'LANG_CN' ,
            (data) => {
                    let tempObj = {};

                    data.LANG_CN.map((lang) => {
                        tempObj[lang.COMN_CODE] = lang.COMN_CN;
                    });

                    setComnLancCode(tempObj);
                    searchNoticeView();
            } ,
            (error) => {console.log("error"); console.log(error)}
        );

        let intervalTime = setInterval(() => {
            const nowTimes = new Date();
            //setNowTime(nowTimes.format(ssUserInfo.SS_USER_DATE_FORMAT + ' HH:mm:ss'));
            const timeshow = (<span>{nowTimes.format(ssUserInfo.SS_USER_DATE_FORMAT + ' HH:mm:ss')}</span>);

            ReactDOM.render(timeshow, document.getElementById('timezone'));
        }, DELAY_TIME);

        return () => {
            clearInterval(intervalTime);
        }
    }, []);

    useEffect(() => {
        setNotReadNotice(props.notReadYn);
    }, [props.notReadYn]);

    const langChangeCallback = (response) => {
        const data = response.data.resultData.V_OUT_JAVA_CURSOR;
        let tempSession = getSessionInfo();

        if(data.length > 0) {
            tempSession['SS_USER_LANG_CN'] = data[0]['LANG_CN'];
            tempSession['SS_USER_LANG_CODE'] = data[0]['LANG_CODE'];

            tempSession['SS_USER_DUTY_NAME'] = data[0]['DUTY_NAME'];
            tempSession['SS_USER_ROLE_NAME'] = data[0]['USER_ROLE_NAME'];
            tempSession['SS_USER_DATE_FORMAT'] = data[0]['DATE_FORMAT'];

            setSsUserInfo(tempSession);
            setCookieUserInfo(JSON.stringify(tempSession));

            langChange(data[0]['LANG_CODE']);
        }
    }

    const langSessionChange = (langCn) => {
        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'   : LANG_CHANGE_PACKAGE,
            'LANG_CN'                   : langCn,
        }
        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            langChangeCallback,
            (e)=>{console.log("에러 "+ e)});
    }

    const selectLang = (langCode) => {
        switch (langCode) {
            case 'KR' :
            case 'KOR' :
                langSessionChange(comnLancCode['KOR']);
                break;
            case 'US' :
            case 'ENG' :
                langSessionChange(comnLancCode['ENG']);
                break;
            case 'CN' :
            case 'CHI' :
                langSessionChange(comnLancCode['CHI']);
                break;
            case 'JP' :
            case 'JPN' :
                langSessionChange(comnLancCode['JPN']);
                break;
            default : break;
        }
    }

    const langChange = (langCode) => {
        switch (langCode) {
            case 'KR' :
            case 'KOR' :
                switchToKorean();
                break;
            case 'US' :
            case 'ENG' :
                switchToEnglish();
                break;
            case 'CN' :
            case 'CHI' :
                switchToChinese();
                break;
            case 'JP' :
            case 'JPN' :
                switchToJapanese();
                break;
            default :
                switchToKorean();
        }

        setLangCode(getLangInfomation());
    }

    const getTabs = () => {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            getContent: () => tab.content,
            key: index
        }));
    }

    const tabChangeHandler = (tabKey) => {
        setTabSelect(tabKey);
    }

    const toggleNoficiation = () => {
        setNotiIsOpen(!notiIsOpen);
    }

    const remoteCallHandler = () => {
        window.open('http://113366.com/sanhait', "_blank");
    }

    const fn_headerMore = () => {
        setNotiIsOpen(false);

        switch (tabSelect){
            /*
            case 0 :
                history.push({pathname : '/app/zz/zz03_0210'});
                break;
            */
            //case 1 :
            case 0 :
                history.push({pathname : '/app/zz/zz03_0400'});
                break;
            default :
                break;
        }
    }

    return (
        <Fragment>
            <div className="header-dots">
                <div id="timezone" className="nowTime mr-3"></div>
                <UncontrolledDropdown isOpen={notiIsOpen}>
                    <DropdownToggle className="p-0 mr-2" color="link" onClick={toggleNoficiation}>
                        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                            <div className="icon-wrapper-bg bg-danger"/>
                            <Ionicon beat={notReadNotice} color="#d92550" fontSize="23px" icon="md-notifications-outline"/>
                            {
                                notReadNotice === true && (
                                    <div className="badge badge-dot badge-dot-sm badge-danger">{MULTI_LANG.HEADER_NOTIFICATION}</div>
                                )
                            }
                        </div>
                    </DropdownToggle>
                    {(notiIsOpen) ? <div style={{position:'fixed',left:'0',top:'0',height:'100%',width:'100%',backgroundColor:'rgba(0, 0, 0, 0)'}} onClick={toggleNoficiation} /> : '' }
                    <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                        <div className="dropdown-menu-header mb-0">
                            <div className="dropdown-menu-header-inner bg-deep-blue">
                                <div className="menu-header-image opacity-1"
                                     style={{
                                         backgroundImage: 'url(' + city3 + ')'
                                     }}
                                />
                                <div className="menu-header-content text-dark">
                                    <h5 className="menu-header-title">{MULTI_LANG.HEADER_NOTIFICATION}</h5>
                                    {/*<h6 className="menu-header-subtitle">{parse(multiLangReplace(MULTI_LANG.HEADER_NOTIFICATION_MESSAGE, ['0']))}</h6>*/}
                                </div>
                            </div>
                        </div>
                        {/* <Tabs tabsWrapperClass="body-tabs body-tabs-alt" transform={false} showInkBar={true} showMore={false} selectedTabKey={tabSelect} onChange={tabChangeHandler} items={getTabs()}/> */}
                        <HeaderNotice setIsOpen={setNotiIsOpen} isOpen={notiIsOpen} setNotReadNotice={setNotReadNotice}/>
                        <Nav vertical>
                            <NavItem className="nav-item-divider"/>
                            <NavItem className="nav-item-btn text-center">
                                <Button size="sm" className="btn-shadow btn-wide btn-pill" color="focus" onClick={fn_headerMore}>
                                    {MULTI_LANG.HEADER_BUTTON_MORE}
                                </Button>
                            </NavItem>
                        </Nav>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown>
                    <DropdownToggle className="p-0 mr-2" color="link">
                        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                            <div className="icon-wrapper-bg bg-focus"/>
                            <div className="language-icon">
                                <Flag className="mr-3 opacity-8" country={langCode.langIconCode} size="40"/>
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right className="rm-pointers">
                        <div className="dropdown-menu-header">
                            <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                                <div className="menu-header-image opacity-05"
                                     style={{
                                         backgroundImage: 'url(' + city2 + ')'
                                     }}
                                />
                                <div className="menu-header-content text-center text-white">
                                    <h6 className="menu-header-subtitle mt-0">{MULTI_LANG.HEADER_LANGUAGE_CHANGE}</h6>
                                </div>
                            </div>
                        </div>
                        {/*<DropdownItem header>Languages</DropdownItem>*/}
                        {langList.map(langItem => (
                            <DropdownItem key={langItem.code} onClick={() => selectLang(langItem.code)}>
                                <Flag className="mr-3 opacity-8" country={langItem.code} />
                                {langItem.name}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown>
                    <DropdownToggle className="p-0 mr-2" color="link" onClick={remoteCallHandler}>
                        <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                            <div className="icon-wrapper-bg bg-primary"/>
                            <Ionicon color="#545cd8" fontSize="23px" icon="md-laptop"/>
                        </div>
                    </DropdownToggle>
                </UncontrolledDropdown>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return({
        notReadYn: state.PropertyState.notReadYn
    });
}

const mapDispatchToProps = dispatch => ({
    setNotReadYn: (notReadYn) => dispatch({type:NOT_READ_NOTICE, notReadYn:notReadYn}) 
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(HeaderDots)));