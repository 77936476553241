import { getSessionInfo } from "./commUtils";

export const INIT_PAGE_SIZE = 50;
export const PAGE_RANGE = ['50', '100', '150', '200'];

export const PAGING_OBJECT = {
    INIT_PAGE_SIZE : INIT_PAGE_SIZE,
    PAGE_RANGE : PAGE_RANGE
}

export const EXCEL_DEFAULT_STYLES = [
  {
      id: 'excel-right-align',
      alignment: {
          horizontal: 'Right',
      },
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
  },
  {
      id: 'excel-left-align',
      alignment: {
          horizontal: 'Left',
      },
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
  },
  {
      id: 'excel-center-align',
      alignment: {
          horizontal: 'Center',
      },
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
  },
  {
      id: 'excel-money',
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
      dataType: 'number',
      numberFormat: { 
        // format: '#,##0.0'
          format: getSessionInfo() && getSessionInfo().SS_DECIMAL_POINT_DIGIT
                    ? '#,##'+Number.parseFloat(0).toFixed(getSessionInfo().SS_DECIMAL_POINT_DIGIT)
                    : '#,##0'
        },
  },
  {
      id: 'excel-number',
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
      dataType: 'number',
      numberFormat: { format: '#,##0' },
  },
  {
      id: 'excel-string',
      interior: {
          color: '#ffffff',
          pattern: 'Solid',
      },
      font: {
          size: 10
      },
      dataType: 'string',
  },
  {
    id: 'excel-date',
    interior: {
        color: '#ffffff',
        pattern: 'Solid',
    },
    font: {
        size: 10
    },
    alignment: {
        horizontal: 'Center',
    },
    dataType: 'dateTime',
    numberFormat: { 
        format: getSessionInfo() &&  getSessionInfo().SS_USER_DATE_FORMAT
                    ?getSessionInfo().SS_USER_DATE_FORMAT.toString().toLowerCase() + ';@'
                    :'yyyy-mm-dd;@' 
        // format: 'mm월dd/yyyy;@' 
    },
  },
  {
    id: 'excel-date-iso',
    interior: {
        color: '#ffffff',
        pattern: 'Solid',
    },
    font: {
        size: 10
    },
    alignment: {
        horizontal: 'Center',
    },
    dataType: 'dateTime',
    numberFormat: { 
        format: getSessionInfo() &&  getSessionInfo().SS_USER_DATE_FORMAT
                    ?getSessionInfo().SS_USER_DATE_FORMAT.toString().toLowerCase() + ';@'
                    :'mm/dd/yyyy;@' 
        // format: 'mm월dd/yyyy;@' 
    },
  },
  {
    id: 'excel-date-time',
    interior: {
        color: '#ffffff',
        pattern: 'Solid',
    },
    font: {
        size: 10
    },
    alignment: {
        horizontal: 'Center',
    },
    // dataType: "dateTime",
    // numberFormat: { format: "yyyy-mm-dd hh:mm:ss;" }
    dataType: 'dateTime',
    numberFormat: { 
        format: getSessionInfo() && getSessionInfo().SS_USER_DATE_FORMAT
                    ?getSessionInfo().SS_USER_DATE_FORMAT.toString().toLowerCase() + ' hh:mm:ss;@'
                    :"yyyy/mm/dd hh:mm:ss;"
        // format: "yyyy년mm/dd hh:mm:ss;",
    },
  },
  {
    id: 'excel-date-time-iso',
    interior: {
        color: '#ffffff',
        pattern: 'Solid',
    },
    font: {
        size: 10
    },
    alignment: {
        horizontal: 'Center',
    },
    // dataType: "dateTime",
    // numberFormat: { format: "yyyy-mm-dd hh:mm:ss;" }
    dataType: 'dateTime',
    numberFormat: { 
        format: getSessionInfo() && getSessionInfo().SS_USER_DATE_FORMAT
                    ?getSessionInfo().SS_USER_DATE_FORMAT.toString().toLowerCase() + ' hh:mm:ss;@'
                    :"yyyy/mm/dd hh:mm:ss;"
        // format: "yyyy년mm/dd hh:mm:ss;",
    },
  },
  {
      id: 'total-style',
      interior: {
          color: '#dddef7',
          pattern: 'Solid',
      },
      font: {
        // color: '#212529',
            color: '#212529',
            bold: true,
            size: 10
      },
  },
  {
      id: 'occupancy-style',
      interior: {
          color: '#f1f1fc',
          pattern: 'Solid',
      },
      font: {
          color: '#212529',
          bold: true,
          size: 10
      },
  },
  {
    id: 'footer',
    borders: {
        borderTop: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
        }
    },
    font: {
        size: 10
    },
  },
  {
      id: 'header',
      interior: {
        color: '#CCCCCC',
        pattern: 'Solid',
      },
      borders: {
        borderBottom: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
        },
        borderRight: {
            color: '#000000',
            lineStyle: 'Continuous',
            weight: 1,
        }
      },
      font: {
          size: 10,
      },
      alignment: {
          vertical: 'Center',
          horizontal: 'Center',
      }
  },
  {
      id: 'excel-array-v1'
  },
  {
      id: 'excel-array-v2'
  }
];

export const NORMAL_STATUS = '0';
export const UPDATE_STATUS = '1';
export const INSERT_STATUS = '2';
export const DELETE_STATUS = '3';
export const ENTER_KEY_VALUE = 13;