import React, {Component, Fragment, useImperativeHandle, useRef} from 'react';

import {
    Select, MenuItem, FormControl, InputLabel,
    InputBase, withStyles, Input, Checkbox,
    ListItemText,
} from '@material-ui/core'

import { Label, Input as BootInput } from 'reactstrap';
import PropTypes from 'prop-types';

import $ from 'jquery'

class zz12_0100_2_GridHideableSelect extends Component {
    constructor(props) {
        console.log('zz12_0100_2_GridHideableSelect constructor');
        super(props);
        this.focusAfterAttached = props.cellStartedEdit;

        let style = {}
        if (this.props.inputProps && this.props.inputProps.style) 
            style = this.props.inputProps.style;

        const emailType = this.props.context.emailTypeParams.names.find( v => v.value === this.props.data.EMAIL_SENDER_SET_CN);
        // console.log("dddddddddddddddddddddd");
        // console.log(emailType);
        // console.log(emailType.key);
        // console.log(emailType && emailType.key === '1' ? true : false);
        // console.log("end dddddddddddddddddd");
        this.state = {
            value : this.props.value,
            isEmailTypeOne : emailType && emailType.key === '1' ? true : false,
            focus: false,
            open: false,
            style: style,
        }
    }

    afterGuiAttached() {
        console.log('zz12_0100_2_GridHideableSelect afterGuiAttached');
        if (this.focusAfterAttached) {
            if (this.isNotUserInput(this.props)) return false;

        }
        console.log(this.state.isEmailTypeOne ? '' : 'none');
        this.setState({
            focus: true,
            open: true,
            
            style: { ...this.state.style,   
                display: this.state.isEmailTypeOne ? '' : 'none',
            }
        });

    };

    isCancelBeforeStart = () => {
        console.log('isCancelBeforeStart');
    }
    isCancelAfterEnd = () => {
        console.log('isCancelAfterEnd');
    }
    isNotUserInput = (props) => {
        if ( !props.hasOwnProperty('context') && !props.context.hasOwnProperty('emailTypeParams')) return false;

        const emailType = props.context.emailTypeParams.names.find( v => v.key === '1');
        if (props.data.EMAIL_SENDER_SET_CN !== emailType.value) 
            return true;

        return false;
    }

    focusIn() {
        console.log('focusIn');
        if (this.isNotUserInput(this.props)) return false;

        this.setState({
            focus: true,
            open: true,
        })
        return true;
    }
    focusOut() {
        console.log('focusOut');
        this.setState({
            focus: false,
            open: false,
        })
        return true;
    }

    getValue() {
        console.log('getValue');
        return this.state.value;
    }

    isPopup() {
        return false;
    }

    render() {
        const onChangeHandler = (event) => {
            if (this.props.callbackFunc2) {
                this.props.callbackFunc2(this.props, event);
            }

            this.setState({value : event.target.value });

            if (this.props.callbackFunc) {
                this.props.setValue(event.target.value);
                this.props.callbackFunc();
            }
        }

        return (
            <div style={{width: '100%', height: '100%' }} className="select-field">
                { 
                // this.state.isEmailTypeOne && 
                    <BootInput 
                        id={`${this.props.column.colId}_${this.props.rowIndex}`} 
                        type="select" name="select" className="dateFieldType"  
                        value={this.state.value == null ? "" : this.state.value} 
                        onChange={onChangeHandler}
                        innerRef={this.myRef}         
                        onClick={(event) =>{
                            console.log(event)
                            console.log(this.state.value)
                        } }
                        onBlur={(event) => {
                            console.log('onBlur');
                            // this.myRef.current.style.borderColor = "#fff";
                        }}
                        style={{
                            padding: 0,
                            ...this.state.style
                        }}
                    >
                        <option disabled selected></option>
                        {this.props.names.map(name => {
                            
                            return (
                                <option value={name.value} key={name.key} >
                                    {name.label}
                                </option>
                            );
                        })}
                    </BootInput>
                }
            </div>
        )
    }
}

export default zz12_0100_2_GridHideableSelect;
