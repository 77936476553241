import React, {useState} from 'react';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {InputGroup, InputGroupAddon, Button, Input} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tutorial from '../Tutorial';
import { injectIntl } from 'react-intl';
import { PAGETITLE_LANG } from './PageTitle_lang';

const PageTitle = injectIntl(({ intl, searchYn, pageId, onChange, onSearch, searchValue, onKeyDown, ...props}) => {
    const { formatMessage } = intl;
    const MULTI_LANG = PAGETITLE_LANG(formatMessage);
  
    const [openTutorialPopup, setOpenTutorialPopup] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const handleClicktutorialButton = () => setOpenTutorialPopup(true);
    const handleClickTutorialClose = () => setOpenTutorialPopup(false);
    const handleAttributeDisabledButton = (disabled) => setDisabledButton(disabled);

    const onChangeHandler = event => {
        if (typeof(onChange) === 'function') {
            onChange(event);
        }
    }

    const onSearchHandler = () => {
        if (typeof(onSearch) === 'function') {
            onSearch();
        }
    }

    const onKeyPressHandler = event => {
        if (typeof(onKeyDown) === 'function') {
            onKeyDown(event);
        }
    }

    return (
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div>{props.titleName}</div>
                </div>
                {
                    searchYn === 'Y' &&
                    <div className="searchBar">
                        <InputGroup>
                                <Input id="searchInput" value={searchValue} onChange={onChangeHandler} onKeyPress={onKeyPressHandler} />
                                <InputGroupAddon addonType="append">
                                    <Button id="searchBtn" color="secondary" onClick={()=>onSearchHandler()}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                    </div>
                }
                <Button color="primary" onClick={handleClicktutorialButton} disabled={disabledButton} style={tutorialStyle(searchYn, 'none')}>
                    {MULTI_LANG.BUTTON_LABEL_TUTORIAL}
                </Button>
            </div>
            <Tutorial pageId={pageId} open={openTutorialPopup} onClose={handleClickTutorialClose} onActionButton={handleAttributeDisabledButton} />
        </div>
    )
});

export { PageTitle };

const tutorialStyle = (searchYn, displayValue) => {
    if (searchYn === 'Y') {
        return { position: 'absolute', right: '22%', display: displayValue };
    }

    return { position: 'absolute', right: '0%', display: displayValue  };
}
