import React from 'react';

import PAGE_500 from '../../pages/error/page_500';

class ErrorHandler  extends React.Component {
    constructor(props){
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info){
        this.setState({hasError: true});
    }

    render() {
        if(this.state.hasError){
            //return <h1>Something went wrong.</h1>;
            return <PAGE_500 />
        }

        return this.props.children;
    }
}

export default ErrorHandler;