export const initialState = {
    menualCheck : false,
    pageNo      : ''
};
export const MENUAL_CHECK = "Layout/MENUAL_CHECK";

const menualState =  (state = initialState, action) => {
    switch (action.type){
        case MENUAL_CHECK:
            return {
                ...state,
                menualCheck: action.menualCheck,
                pageNo : action.pageNo
            };
        default :
            return state;
    }
}

export default menualState;
