import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const Pages = lazy(() => import('../../pages/'));
const Login = lazy(() => import('../../pages/login'))

const Publish = lazy(() => import('../../publish/'));

const ComponentList = {
    Pages : Pages,
    Login : Login,
    Publish : Publish
}

const AppMain = () => {
    const [defaultMenu, setDefaultMenu] = React.useState("/login/zz/zz01_0100");
    const [menuList, setMenuList] = React.useState([
        {key : "001", pageUrl : "/pages", pageComponent : "UserPages", pageLoadingMsg : "Please wait while we load all the UserPages Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "002", pageUrl : "/apps", pageComponent : "Applications", pageLoadingMsg : "Please wait while we load all the Applications Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "003", pageUrl : "/dashboards", pageComponent : "Dashboards", pageLoadingMsg : "Please wait while we load all the Dashboards Page", pageLoadingMsg2 : "Wait Plz."},

        {key : "004", pageUrl : "/widgets", pageComponent : "Widgets", pageLoadingMsg : "Please wait while we load all the Widgets Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "005", pageUrl : "/elements", pageComponent : "Elements", pageLoadingMsg : "Please wait while we load all the Elements Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "006", pageUrl : "/components", pageComponent : "Components", pageLoadingMsg : "Please wait while we load all the Components Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "007", pageUrl : "/charts", pageComponent : "Charts", pageLoadingMsg : "Please wait while we load all the Charts Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "008", pageUrl : "/forms", pageComponent : "Forms", pageLoadingMsg : "Please wait while we load all the Forms Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "009", pageUrl : "/tables", pageComponent : "Tables", pageLoadingMsg : "Please wait while we load all the Tables Page", pageLoadingMsg2 : "Wait Plz."},

        {key : "010", pageUrl : "/app", pageComponent : "Pages", pageLoadingMsg : "Please wait while we load all the Page", pageLoadingMsg2 : "Wait Plz."},
        {key : "011", pageUrl : "/login", pageComponent : "Login", pageLoadingMsg : "Please wait while we load all the Login", pageLoadingMsg2 : "Wait Plz."},

        {key : "012", pageUrl : "/publish", pageComponent : "Publish", pageLoadingMsg : "Please wait while we load all the Publish", pageLoadingMsg2 : "Wait Plz."},
    ]);

    //console.log(menuList);

    return (
        <Fragment>
            {menuList.map(menuItem => (
                <Suspense key={menuItem.key} fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse"/>
                            </div>
                            <h6 className="mt-3">
                                {menuItem.pageLoadingMsg}
                                <small>{menuItem.pageLoadingMsg2}</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path={menuItem.pageUrl} component={ComponentList[menuItem.pageComponent]}/>
                </Suspense>
            ))}

            <Route exact path="/" render={() => (
                <Redirect to={defaultMenu}/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;
