/**
 * 파일명       : ModifyButton.js
 * 파일 설명	: 프로그램 관리 그리드 수정 버튼
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 이진희 2020.09.23  최초 작성
*/

import React from "react";
import {Button} from "reactstrap";
import {nullToBlank} from "../../../../utils/commUtils";
import {UPDATE_STATUS, INSERT_STATUS} from "../../../../utils/globalValue";

const ModifyButton = props => {
    /**
     * @author JinHui Lee 
     * @description Modify Button Function
    */
    const ModifyHandler = () => {
        if (props.node.data.ROWTYPE !== INSERT_STATUS) {
            props.node.data.ROWTYPE = UPDATE_STATUS;
            props.api.redrawRows();
        }

        const index = props.rowIndex;
        let cols = props.columnApi.getAllGridColumns();

        if (cols) {
            let index = 0;

            if (nullToBlank(cols[index]) !== '') getEditCell(cols, index);
        }

        const movableCells =  props.columnApi.getAllColumns().filter(item => item.colDef.editable && !item.colDef.suppressNavigable);

        props.api.setFocusedCell({
            rowIndex: index,
            colKey: movableCells[0].colId
        });

        props.api.startEditingCell({
            rowIndex: index,
            colKey: movableCells[0].colId
        });
    }

    /**
     * @author JinHui Lee 
     * @description 수정 가능 Cell Column Index
     * @param {Array} cols
     * @param {Number} index
    */
    const getEditCell = (cols, index) => {
        if (!cols[index].colDef.editable) {
            index++;
            getEditCell(cols, index);
        }
    }

    return (
        <>
            <div className="d-flex w-100 he-100 align-items-center justify-content-center">
                <Button
                    className="modify-ic"
                    onClick={() => ModifyHandler()}
                    color="link"
                />
            </div>
        </>
    )
}

export {ModifyButton};