import React from "react";

export const HEADER_LANG = (formatMessage) => {
    let MULTI_LANG = {
        HEADER_NOTIFICATION             : formatMessage({id: "header.title.notification",           defaultMessage: "Notification"}),
        HEADER_NOTIFICATION_MESSAGE     : formatMessage({id: "header.title.notificationMessage",    defaultMessage: "읽지 않은 메시지 <b>@(0)</b>건이 있습니다."}),
        HEADER_BUTTON_MORE              : formatMessage({id: "header.button.more",                  defaultMessage: "More"}),
        HEADER_LANGUAGE_CHANGE          : formatMessage({id: "header.title.languageChange",         defaultMessage: "Choice Language"}),
        TITLE_WEB_NAME                  : formatMessage({id: "comn.title.cms_system_name",          defaultMessage: "WINGSCMS"}),
        HEADER_EMERGENCY                : formatMessage({id: "header.title.emergency",              defaultMessage: "긴급"}),
        HEADER_IMPORTANT                : formatMessage({id: "header.title.important",              defaultMessage: "중요"}),
        HEADER_NORMAL                   : formatMessage({id: "header.title.normal",                 defaultMessage: "일반"}),
        HEADER_NODATA                   : formatMessage({id: "header.message.noData",               defaultMessage: "등록된 데이터가 없습니다."}),
        HEADER_LANG_KOR                 : formatMessage({id: "header.title.langKorean",             defaultMessage: "한국어"}),
        HEADER_LANG_ENG                 : formatMessage({id: "header.title.langChinese",            defaultMessage: "English"}),
        HEADER_LANG_CHI                 : formatMessage({id: "header.title.langEglish",             defaultMessage: "中國語"}),
        HEADER_LANG_JPN                 : formatMessage({id: "header.title.langJapanese",           defaultMessage: "日本語"}),
        HEADER_SELECT_PROPERTY          : formatMessage({id: "header.title.selectProperty",         defaultMessage: "프로퍼티를 선택하세요."}),
        HEADER_SEARCH_PROPERTY          : formatMessage({id: "header.title.searchProperty",         defaultMessage: "검색"}),
    }

    return MULTI_LANG;
}
