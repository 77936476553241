import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import ErrorHandler from './components/Error/ErrorHandler';
import './assets/base.scss';
import './assets/ag-grid/ag-grid.css'
import './assets/ag-grid/ag-theme-balham.css'
import './assets/member/mem-base.scss'
import Main from './Layout';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import {IntlProviderWrapper} from "./config/ConnectedIntlProvider";


import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("SANHA_IT__multi_1_Devs__23_August_2020_[v2]_MTU5ODE0MDgwMDAwMA==dc24d0a39d55b442475cefcf2bd47dda");

let profiles = '';

if(process.env.NODE_ENV === 'production') {
    profiles = 'PROD'
}else {
    profiles = 'LOCAL'
}

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <IntlProviderWrapper>
                <ErrorHandler>
                      <HashRouter>
                         <Component />
                    </HashRouter>
                </ErrorHandler>
            </IntlProviderWrapper>
        </Provider>,
        rootElement
    );
};

renderApp(Main);
/*
if (module.hot) {
    module.hot.accept('./DemoPages/Main', () => {
        const NextApp = require('./DemoPages/Main').default;
        renderApp(NextApp);
    });
}
*/

serviceWorker.unregister();

