import React, { Component } from 'react';

import dateFormat from 'dateformat';
import { Input } from 'reactstrap';

import { numberWithCommas, numberCommaWithDigitPoint, stringDateFormatChange } from '../../utils/commUtils';

class NumberCellEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value + ''
        }

        this.focusAfterAttached = props.cellStartedEdit;
        this.numberInputEl = React.createRef();
    }

    getValue = () => Number(this.state.value);
    isPopup = () => false;

    focusIn = () => {
        this.numberInputEl.focus();
        this.numberInputEl.select();
    };

    afterGuiAttached() {        
        if (this.focusAfterAttached)
            this.numberInputEl.current.focus();
    }

    handleChange = (event) => {
        if (!isKeyPressedNumeric(event)) {
            if (event.preventDefault) 
                event.preventDefault();

            event.returnValue=false;
        }

        let value = event.target.value;
        if (isNaN(Number(value.replace(/[^0-9]/gi,'')))) {
            value = '';
        } else {
            value = value.replace(/[^0-9]/gi,'');
        }

        this.setState({
            value: value,
        });
    }

    render() {
        return (
            <Input
                onChange={this.handleChange}
                value={this.state.value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                innerRef={this.numberInputEl}
                { ...this.props.inputProps }
            />
        );
    }
}

class GridNumberCellRenderer extends Component {
    focusIn () {
        return true;
    }

    render () {
        const num = numberWithCommas(this.props.value);
        return num;
    }
}

const GridMoneyCellRenderer = (params) => {
    return numberCommaWithDigitPoint(params.value);
}

const GridDateFieldCellRenderer = (params) => {
    const value = params.value;
    const number = /^[0-9]{8}$/;

    if (number.test(value)) 
        return stringDateFormatChange(value);
    
    return value;
};


const GridDateFieldDateToStrCellRenderer = (params) => {
    const value = params.value;
    if (value) {
        const tempDate = new Date(value);
        
        return dateFormat(tempDate, 'yyyy-mm-dd HH:MM:ss');
    }
    return '';
};

export {
    NumberCellEditor ,
    GridDateFieldCellRenderer,
    GridDateFieldDateToStrCellRenderer,
    GridNumberCellRenderer,
    GridMoneyCellRenderer,
} ;

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr) || charStr == "";
}

function isKeyPressedNumeric(event) {
    var charCode = getCharCodeFromEvent(event);

    if (charCode > 95 && charCode < 106) {
        return true;
    }

    if ((charCode > 36 && charCode < 41) || charCode == 46) {
        return true;
    }

    var charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}
