import axios from 'axios';

import {ErrorPopup, WarningPopup, SuccessPopup} from "../components/ComponentsList";
import React from "react";
import ReactDOM from 'react-dom';
import {commonLang} from "./commonLang";
import {
    multiLangMessage,
    nullToBlank,
    initCookie,
    lpad,
    saveLocalStorage,
    loadLocalStorage,
    removeLocalStorage,
    getSessionInfo,
    showLoading,
    hideLoading
} from "./commUtils";
import { SessionReset } from '../Layout/AppHeader';
import { SESSION_EXPIRATION_TIME } from '../reducers/propertyState';
import configureStore, { store, store123 } from '../config/configureStore';

const parse = require('html-react-parser');

let CONNECTION_TIMEOUT = 90000;
let SERVER_URL = process.env.REACT_APP_BACKEND_HOST;
let UTILS_SERVER_URL = process.env.REACT_APP_BACKEND_UTILS_HOST;
let API_KEY = process.env.REACT_APP_API_KEY;
let VENDDR_ID = process.env.REACT_APP_VENDOR_ID;
let BOOKING_ENGINE_URL = process.env.REACT_APP_BOOKING_ENGINE_URL;
let BOOKING_API_ENGINE_URL = process.env.REACT_APP_BOOKING_API_ENGINE_URL;
let COMMON_CODE_SELECT = "PG_ZZ00_COMN_COMBO_LIST.PR_ZZ_COMN_CODE_LIST_SEL";
let ERROR_POPUP_YN = false;

const options = {
    method : 'POST',
    timeout : CONNECTION_TIMEOUT,
    responseType : 'JSON',
    responseEncoding : 'utf8',
}

function initOptions(method, opt, params, utilsYn){
    options.url = SERVER_URL + opt.url;
    if(utilsYn){
        options.url = UTILS_SERVER_URL + opt.url;
    }

    options.method = method;
    options.data = params;
    options.withCredentials = true;

    if(nullToBlank(opt.responseType) != ""){
        options.responseType = opt.responseType;
    }else{
        options.responseType = 'JSON';
    }

    if(nullToBlank(opt.headers) !== ""){
        options.headers = opt.headers;
    }

    options.headers = {
        'API-KEY'   : API_KEY,
        'VENDOR_ID' : VENDDR_ID
    }

    if(opt.timeout != null && opt.timeout != 'undefined' && opt.timeout != "") {
        options.timeout = opt.timeout;
    }

    return options;
}

export function convertFormdata(object, formData){
    if(formData == null || formData == '' || formData == 'undefined') {
        formData = new FormData();
    }

    Object.keys(object).forEach(key => {
        if(!object[key] && key){
            formData.append(key, '""');
        }else{
            formData.append(key, paramSerialize(object[key]));
        }
    });

    return formData;
}

const errorPopupCallback = (result, errorFunc) => {
    ERROR_POPUP_YN = false;

    if(typeof errorFunc === 'function'){
        errorFunc(result);
    }
}

export function paramSerialize(params){
    //return JSON.stringify(params).replace(/,/g, String.fromCharCode(20));
	return JSON.stringify(params);
}

export function sessionReset() {
    let test = store.getState();
    let sessionTimeout = test.PropertyState.sessionTimeout;
    store.dispatch({type:SESSION_EXPIRATION_TIME, sessionExpirationTime:new Date().setMinutes(new Date().getMinutes() + sessionTimeout)})
}

export function postAxios(options, params, callbackFunc, errorFunc, callbackParams, noLoading, excepErrorPop=false){
    let MULTI_LANG = commonLang();
    initCookie("LOGIN_INFO");

    params['NAVIGATOR_USERAGENT'] = navigator.userAgent;

    const formParam = convertFormdata(params);
    const sendOpt = initOptions('POST', options, formParam);

    if(!noLoading){
        showLoading();
    }

    axios(
        sendOpt
    ).then(function(response){
        let result = response.data;

        if(result.serverStatus != '200') {
            if(!ERROR_POPUP_YN) {
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                errorModalFunction(MULTI_LANG, "Error Message", errorPopupCallback, result, errorFunc);
            }
        } else if(result.userStatus.code != '2000' && result.userStatus.code != '1000') {
            if(!ERROR_POPUP_YN) {
                const errorMessage = result.userStatus.message;
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                if (result.userStatus.code == '7000') {
                    const errorObject = JSON.parse(errorMessage);

                    errorModalFunction(MULTI_LANG, errorObject.error_message, errorPopupCallback, result, errorFunc);
                } else if(result.userStatus.code == '7100') {
                    const errorObject = JSON.parse(errorMessage);

                    errorModalFunction(MULTI_LANG, errorObject.message, errorPopupCallback, result, errorFunc);
                } else if(result.userStatus.code == '7200') {
                    const errorObject = JSON.parse(errorMessage);

                    warningModalFunction(MULTI_LANG, errorObject.message, errorPopupCallback, result, errorFunc);
                } else {
                    errorModalFunction(MULTI_LANG, multiLangMessage({id: result.userStatus.multiLangKey, defaultMessage: errorMessage}), errorPopupCallback, result, errorFunc);
                }
            }
        }else if(typeof(callbackFunc) === 'function'){
            return callbackFunc(response, callbackParams);
        }
    }).catch(function(err){
        if(!noLoading) {
            hideLoading();
        }
        if(typeof(errorFunc) === 'function'){
            errorFunc(err);
        }

        if(err.code === "ECONNABORTED" && excepErrorPop === false){
            errorModalFunction(MULTI_LANG, parse(MULTI_LANG.ERROR_TIMEOUT_MESSAGE), errorPopupCallback, null, errorFunc);
        }
    }).finally(() => {
        if(!noLoading) {
            hideLoading();
        }
    })
}

export const errorModalFunction = (MULTI_LANG, errorMessage, errorPopupCallbackFunc, result, errorFunc) => {
    let COMM_MULTI_LANG = commonLang();

    if(typeof errorPopupCallbackFunc !== "function"){

        errorPopupCallbackFunc = errorPopupCallback;
    }

    const ErrorPopupModal = () => {
        const [errorOpen, setErrorOpen] = React.useState(true);

        return (
            <ErrorPopup
                modalType={"Error"}
                openState={errorOpen}
                setOpenState={setErrorOpen}
                sendYn={true}
                title={nullToBlank(MULTI_LANG.POPUP_HEADER_ERROR == "") ? COMM_MULTI_LANG.POPUP_HEADER_ERROR : MULTI_LANG.POPUP_HEADER_ERROR }
                content={errorMessage}
                callback={() => errorPopupCallbackFunc(result, errorFunc)}
            />
        )
    }

    ReactDOM.render(<ErrorPopupModal/>, document.getElementById('modalTarget'));
}

export const warningModalFunction = (MULTI_LANG, warningMessage, warningPopupCallback, result, errorFunc) => {
    let COMM_MULTI_LANG = commonLang();

    if(typeof warningPopupCallback !== "function"){
        warningPopupCallback = errorPopupCallback;
    }

    const WarningPopupModal = () => {
        const [warningOpen, setWarningOpen] = React.useState(true);

        return (
            <WarningPopup
                modalType={"Warning"}
                openState={warningOpen}
                setOpenState={setWarningOpen}
                sendYn={true}
                title={nullToBlank(MULTI_LANG.POPUP_HEADER_WARNING == "") ? COMM_MULTI_LANG.POPUP_HEADER_WARNING : MULTI_LANG.POPUP_HEADER_WARNING}
                content={warningMessage}
                callback={() => warningPopupCallback(result, errorFunc)}
            />
        )
    }

    ReactDOM.render(<WarningPopupModal/>, document.getElementById('modalTarget'));
}

export const successModalFunction = (MULTI_LANG, successMessage, successPopupCallback) => {
    let COMM_MULTI_LANG = commonLang();

    const SuccessPopupModal = () => {
        const [successOpen, setSuccessOpen] = React.useState(true);

        return (
            <SuccessPopup
                modalType={"Success"}
                openState={successOpen}
                setOpenState={setSuccessOpen}
                title={nullToBlank(MULTI_LANG.POPUP_HEADER_SUCCESS == "") ? COMM_MULTI_LANG.POPUP_HEADER_SUCCESS : MULTI_LANG.POPUP_HEADER_SUCCESS}
                content={successMessage}
                callback={successPopupCallback}
            />
        )
    }

    ReactDOM.render(<SuccessPopupModal/>, document.getElementById('modalTarget'));
}

export function getCommonCode(typeCode, callbackFunc, errorFunc){
    const formParam = convertFormdata({
        'PROCEDURE_OR_TABLE_NAME' : COMMON_CODE_SELECT,
        'TYPE_CODE' : typeCode,
        'NAVIGATOR_USERAGENT' : navigator.userAgent
    });

    const sendOpt = initOptions('POST', {
        url : '/comm/jsonProcess_Procedure_Commcode'
    }, formParam);

    axios(
        sendOpt
    ).then(function(response){
        if(typeof(callbackFunc) === 'function'){
            callbackFunc(response.data.resultData);
        }
    }).catch(function(err){
        if(typeof(errorFunc) === 'function'){
            errorFunc(err);
        }
    });
}

export function getCommonCodeForCode(typeCode, comnCode, callbackFunc, errorFunc){
    const formParam = convertFormdata({
        'PROCEDURE_OR_TABLE_NAME' : COMMON_CODE_SELECT,
        'TYPE_CODE' : typeCode,
        'COMN_CODE' : comnCode,
        'NAVIGATOR_USERAGENT' : navigator.userAgent
    });

    const sendOpt = initOptions('POST', {
        url : '/comm/jsonProcess_Procedure_Commcode'
    }, formParam);

    axios(
        sendOpt
    ).then(function(response){
        if(typeof(callbackFunc) === 'function'){
            callbackFunc(response.data.resultData);
        }
    }).catch(function(err){
        if(typeof(errorFunc) === 'function'){
            errorFunc(err);
        }
    });
}

export function sendEmail(emailAlertNo, callback, errorCallback, successMessage, errorMessage, resultPopupYn='Y'){
    const options = {
        method: 'POST',
        url: "/utils/email_send"
    }

    const formParam = convertFormdata({
        EMAIL_SEND_NO : emailAlertNo
    });
    const sendOpt = initOptions('POST', options, formParam, true);

    axios(
        sendOpt
    ).then(function(response){

        const result = response.data.resultData;
        let failFlag = true;

        for (let i=0; i<result.length; i++) {
            const data = JSON.parse(result[i]);

            if(data.status == "0"){
                failFlag = false;
            }else{
                failFlag = true;
                break;
            }
        }

        let MULTI_LANG = commonLang();

        if (resultPopupYn === 'Y') {
            if(!failFlag){
                if(nullToBlank(successMessage) !== ""){
                    successModalFunction(MULTI_LANG, successMessage, callback);
                }else{
                    successModalFunction(MULTI_LANG, MULTI_LANG.POPUP_EMAIL_SEND_SUCCESS, callback);
                }
            }else{
                if(nullToBlank(errorMessage) !== ""){
                    warningModalFunction(MULTI_LANG, errorMessage, errorCallback);
                }else{
                    warningModalFunction(MULTI_LANG, MULTI_LANG.POPUP_EMAIL_SEND_FAIL, errorCallback);
                }
            }
        }
        
/*
        if(typeof callback == "function"){
            callback(data);
        }
 */
    }).catch(function(err){
        console.error(err);
    })
}

export function fileUpload(file, callback, errorCallback, params = {}) {
    const options = {
        method: 'POST',
        url: "/utils/file_upload",
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    const formParam = convertFormdata(params);
    const fileOpt = initOptions('POST', options, formParam, true);

    for (let i=0; i<file.length; i++) {
        fileOpt.data.append('file',file[i]);
    }

    axios(
        fileOpt
    ).then(function(response){

        let result = response.data;
        let MULTI_LANG = commonLang();

        if(result.serverStatus != '200') {
            if(!ERROR_POPUP_YN) {
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                errorModalFunction(MULTI_LANG, "Error Message", errorPopupCallback);
            }
        } else if(result.userStatus.code != '2000' && result.userStatus.code != '1000') {
            if(!ERROR_POPUP_YN) {
                const errorMessage = result.userStatus.message;
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                if (result.userStatus.code == '7000') {
                    const errorObject = JSON.parse(errorMessage);

                    errorModalFunction(MULTI_LANG, errorObject.error_message, errorPopupCallback);
                } else if(result.userStatus.code == '7100') {
                    const errorObject = JSON.parse(errorMessage);

                    errorModalFunction(MULTI_LANG, errorObject.message, errorPopupCallback);
                } else if(result.userStatus.code == '7200') {
                    const errorObject = JSON.parse(errorMessage);

                    warningModalFunction(MULTI_LANG, errorObject.message, errorPopupCallback);
                } else {
                    errorModalFunction(MULTI_LANG, multiLangMessage(result.userStatus.multiLangKey, errorMessage), errorPopupCallback);
                }
            }
        }else if(typeof(callback) === 'function'){
            return callback(response);
        }
    }).catch(function(err){
        if(typeof(errorCallback) === 'function'){
            errorCallback(err);
        }
    })
}

export function apiAxios(option, params, callbackFunc, errorFunc, callbackParams) {
    let MULTI_LANG = commonLang();

    option.timeout = options.timeout;
    option.responseType = options.responseType;
    option.responseEncoding = options.responseEncoding;
    option.params = params;
    
    axios(
        option
    ).then(function(response){
        let result = response.data;

        hideLoading();

        if(result.serverStatus != '200') {
            if(!ERROR_POPUP_YN) {
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                errorModalFunction(MULTI_LANG, "Error Message", errorPopupCallback);
            }
        } else if(result.userStatus.code != '2000') {
            if(!ERROR_POPUP_YN) {
                const errorMessage = result.userStatus.message;
                //ERROR_POPUP_YN = true;
                ERROR_POPUP_YN = false;

                if (result.userStatus.code == '3000') {
                    const errorObject = errorMessage;

                    //warningModalFunction(MULTI_LANG, errorObject, errorPopupCallback);
                } else if (result.userStatus.code == '4000') {
                    const errorObject = errorMessage;

                    //errorModalFunction(MULTI_LANG, errorObject, errorPopupCallback);
                } else {
                    const errorObject = errorMessage;

                    //errorModalFunction(MULTI_LANG, errorObject, errorPopupCallback);
                }
            }
        }else if(typeof(callbackFunc) === 'function'){
            return callbackFunc(response, callbackParams);
        }
    }).catch(function(err){
        hideLoading();

        if(typeof(errorFunc) === 'function'){
            errorFunc(err);
        }
    })
}

export const wrongAuthToken = (callback) => {
    let MULTI_LANG = commonLang();

    warningModalFunction(MULTI_LANG, MULTI_LANG.POPUP_MESSAGE_WRONG_TOKEN, callback);
}

export const saveTempLoginHistroy = (tempUser) => {
    if(nullToBlank(tempUser) != ""){
        let disDbNoStr = lpad(tempUser.DISTRIBUTED_DB_NO, 2, '0');
        let pmNoStr = lpad(tempUser.PM_NO, 20, '0');
        let userNoStr = lpad(tempUser.USER_NO, 20, '0');

        let userStr = disDbNoStr+pmNoStr+userNoStr;

        saveLocalStorage("TEMP_HIS_INFO", userStr);
    }
}

export const loginHistory = (logType, params) => {
    if(logType === "LOGIN"){
        saveTempLoginHistroy(params);
    }else{
        let userStr = loadLocalStorage("TEMP_HIS_INFO");

        removeLocalStorage("TEMP_HIS_INFO");

        if(nullToBlank(userStr) == ""){
            return false;
        }

        params = {
            DISTRIBUTED_DB_NO   : parseInt(userStr.substring(0,2)),
            PM_NO               : parseInt(userStr.substring(2,22)),
            USER_NO             : parseInt(userStr.substring(22,42))
        }
    }

    if(nullToBlank(params) == ""){
        return false;
    }

    const formParam = convertFormdata({
        PROCEDURE_OR_TABLE_NAME     : 'PG_ZZ00_USER_OPER_HIST.PR_LO_ZZ_USER_LOGIN_HIST_SAVE',
        NAVIGATOR_USERAGENT         : navigator.userAgent,
        DISTRIBUTED_DB_NO           : params.DISTRIBUTED_DB_NO + "",
        USER_NO                     : params.USER_NO + "",
        PM_NO                       : params.PM_NO + "",
        LOG_IN_OUT_TYPE             : logType
    });

    const sendOpt = initOptions('POST', {
        url : '/zz01/login_history'
    }, formParam);

    axios(
        sendOpt
    ).then(function(response){
    }).catch(function(err){ })
}

export const pageAccessHistory = (pageInfo) => {
    getCommonCodeForCode('USER_OPERATION_CN', '3', (result) => {
        const userInfo = getSessionInfo();
        const userOperCn = result['USER_OPERATION_CN'][0]['COMN_CN'];
        const nowDate = new Date().format('YYYYMMDDHHmmss');

        const formParam = convertFormdata({
            PROCEDURE_OR_TABLE_NAME     : 'PG_ZZ00_USER_OPER_HIST.PR_LO_USER_RPT_EXL_HIST_SAVE',
            NAVIGATOR_USERAGENT         : navigator.userAgent,
            PM_NO                       : userInfo.SS_PMS_NO,
            USER_NO                     : userInfo.SS_USER_NO + "",
            PAGE_NO                     : pageInfo.PAGE_NO,
            USER_OPERATION_CN           : userOperCn,
            PROC_DT                     : nowDate
        });

        const sendOpt = initOptions('POST', {
            url : '/comm/jsonProcess_Procedure'
        }, formParam);

        axios(
            sendOpt
        ).then(function(response){
        }).catch(function(err){ })
    });
}

export const fileDownload = (fileAttcNo, fileAttcDtlNo, fileName, callback, errorCallback) => {
    const fileDownloadPackage = "PG_ZZ00_FILE_UPLOAD.PR_TB_ZZ_ATTACH_FILES_SEL";
    if(nullToBlank(fileAttcNo) == "" || nullToBlank(fileAttcDtlNo) == ""){
        return false;
    }

    const dataString = {
        PROCEDURE_OR_TABLE_NAME : fileDownloadPackage,
        ATTC_FILE_NO            : fileAttcNo + "",
        ATTC_FILE_DTL_NO        : fileAttcDtlNo + ""
    }

    const options = {
        method: 'POST',
        url: "/utils/file_download",
        responseType : 'blob'
    }

    const formParam = convertFormdata(dataString);
    const fileOpt = initOptions('POST', options, formParam, true);

    axios(
        fileOpt
    ).then(response => {
        try {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })

            if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                if(nullToBlank(fileName) != "") {
                    window.navigator.msSaveOrOpenBlob(blob, fileName)
                }else {
                    window.navigator.msSaveOrOpenBlob(blob);
                }
            } else { // not IE
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.target = '_self'
                if (nullToBlank(fileName) != "") {
                    link.download = fileName;
                }else{
                    link.download = "";
                }
                link.click();
            }

            if(typeof callback === "function"){ callback(response); }
        } catch (e) {
            console.error(e)
        }
    }).catch(res => {
        if(typeof errorCallback === "function"){ errorCallback(res.response.data); }
    }).finally(() => {
    });
}

export const apiResultMsg = (resultObj) => {
     if (resultObj.Warnings) {
        if (resultObj["Warnings"].length > 0) {
            warningModalFunction(commonLang(), resultObj["Warnings"][0].Message);
        }

        return false;
    }

    if (resultObj.Errors) {
        if (resultObj["Errors"].length > 0) {
            warningModalFunction(commonLang(),parse(commonLang().ERROR_COMMON_MESSAGE));
            //warningModalFunction(commonLang(), resultObj["Errors"][0].Message);
        }
        
        return false;
    }

    return true;
}

export const commonRequest = (callbackFunc, formData, noShowLoading=false, callbackParam={}, userUrl="/comm/jsonProcess_Procedure", errFunc=()=>{}, excepErrorPop=false) => {
    postAxios(
        {
            method: 'POST',
            url: userUrl
        },
        formData,
        callbackFunc,
        errFunc,
        callbackParam,
        noShowLoading,
        excepErrorPop
    );
}

export const bookingEnginURL = () => {
    return BOOKING_ENGINE_URL;
}

export const bookingEnginApiURL = () => {
    return BOOKING_API_ENGINE_URL;
}

export function getBeVersion(cn_pm_code, callback, errorCallback){
    const options = {
        method: 'POST',
        url: "/utils/be_version_get"
    }

    const formParam = convertFormdata({
        CN_PM_CODE: cn_pm_code
    })

    const versionOpt = initOptions('POST', options, formParam, true);

    axios(
        versionOpt
    ).then(function (response){
        let result = response.data;
        if(typeof callback === "function"){ callback(response); }

    }).catch(function (err){
        errorCallback(err);
        if(typeof(errorCallback) === 'function'){
            errorCallback(err);
        }
    })
}

export const personalApiReq = (callbackFunc, prsnInfo, apiInfo, method) => {
    let apiYn = nullToBlank(apiInfo["PRSN_API_YN"]) === '' ? 'N' : apiInfo["PRSN_API_YN"];

    if (apiYn !== 'Y') {
        return;
    }

    if (nullToBlank(prsnInfo.HOTEL_INFO) === '') {
        return;
    }

    let tempInfo = prsnInfo.HOTEL_INFO.split('|');
    let bsnsCode = '11';
    let propertyCode = '11';

    if (tempInfo.length > 0) {
        bsnsCode = tempInfo[0];
        propertyCode = tempInfo[1];
    }

    const option = {
        method: method,
        timeout: options.timeout,
        responseType: options.responseType,
        responseEncoding: options.responseEncoding,
        url: apiInfo.URL,
        headers: {
            'Content-Type': apiInfo.CONTENT_TYPE,
            'API-KEY': apiInfo.API_KEY,
            'VENDOR_ID': apiInfo.VENDOR_ID,
            'ECHO_TOKEN': apiInfo.ECHO_TOKEN
        },
        data : {
            systemInfo: { code: apiInfo.SYSTEM_CODE, program: "CMS" },
            hotelInfo: {
                code: prsnInfo.HOTEL_CODE,
                businessCode: bsnsCode,
                propertyNo: propertyCode
            },
            accessInfo: {
                userId: prsnInfo.USER_ID,
                userIp: { 
                    "privateIp": nullToBlank(getSessionInfo()['SS_USER_IP']) === '' ? "0.0.0.0" : getSessionInfo()['SS_USER_IP'].replace(/\"/gi, ''),
                    "publicIp": nullToBlank(getSessionInfo()['SS_USER_IP']) === '' ? "0.0.0.0" : getSessionInfo()['SS_USER_IP'].replace(/\"/gi, '')
                },
                requestFunctionType: prsnInfo.FUNC_TYPE,
                requestParameters: JSON.stringify(prsnInfo.PARAMETER),
                requestPageStack: prsnInfo.PAGE_NAME,
                requestReason: prsnInfo.REASEON,
                numberOfResults: String(prsnInfo.RESULT_CNT)
            }
        }
    }

    apiAxios(option, {}, callbackFunc, error => {console.log(error)});
}