
/**
 * 파일명       : zz12_0100_2_EmailType.js
 * 파일 설명	: 이메일 타입 콤보 Render
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 정훈의  2020.02.20  최초 작성
*/
import React, { Component } from 'react';
import {Input} from "reactstrap";
import $ from 'jquery'

class ZZ12_0100_2_EmailType extends Component {
    constructor(props) {
        super(props);
        
        this.focusAfterAttached = props.cellStartedEdit;
        this.myRef = React.createRef();

        this.state = {
            value: this.props.value,
            email: this.props.data.EMAIL,
        }
    }

    afterGuiAttached() {
        if (this.focusAfterAttached) {
            this.myRef.current.focus();
        }
    };

    componentWillMount() {}

    getValue() {
        return this.state.value;
    }

    render() {
        /**
         * @author hoonui Jeong
         * @description Save Button Function
        */
        const onEmailTypeChange = (event) => {
            const value = parseInt(event.target.value);
            const node = this.props.node;
            const api = this.props.api;
            node.setDataValue('EMAIL_SENDER_SET_CN', value);
            this.setState({ value: value });
            
            if ( this.props.names.find( v => v.value === value ).key === '1' ) {
                $(this.props.eGridCell.offsetParent).children().eq(3).find('select').css('display', '');
            } else {
                $(this.props.eGridCell.offsetParent).children().eq(3).find('select').css('display', 'none');
            }
        }

        const onEmailChange = (event) => {
            const value = event.target.value;
            const node = this.props.node;
            node.setDataValue('EMAIL', value);
            this.setState({ email: value });
        }

        const specifyValueFlagFunc = () => {
            let findEmailType = this.props.names.find(emailTypeItem => emailTypeItem.value === this.state.value); 

            if (findEmailType && findEmailType["key"] === '1') return true;

            return false;
        }

        return (
            <>
                <div style={{display: 'flex', width: '100%'}}>
                    <div style={{flexGrow: 1}}>
                        <Input
                            type="select" 
                            value={this.state.value}
                            innerRef={this.myRef}
                            onChange={onEmailTypeChange}
                        >
                            <option disabled selected></option>
                            {this.props.names.map(name => {
                                return (
                                    <option value={name.value} key={name.key}>
                                        {name.label}
                                    </option>
                                );
                            })}
                        </Input> 
                    </div>
                    { 
                        !this.state.value 
                        || specifyValueFlagFunc() 
                        && (
                            <div style={{flexGrow: 4}}>
                                <Input 
                                    id="zz12_0100_2_Emailtype__email"
                                    value={this.state.email}
                                    onChange={onEmailChange}
                                />
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
};

export default ZZ12_0100_2_EmailType;