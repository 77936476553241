import React from "react";
import {multiLangMessage} from "./commUtils";

export const commonLang = () => {
    const formatMessage = multiLangMessage;

    const MULTI_LANG = {
        POPUP_HEADER_SUCCESS        : formatMessage({id: "com.popup.success",               defaultMessage: "성공"}),
        POPUP_HEADER_WARNING        : formatMessage({id: "com.popup.warning",               defaultMessage: "경고"}),
        POPUP_HEADER_ERROR          : formatMessage({id: "com.popup.error",                 defaultMessage: "에러"}),
        POPUP_EMAIL_SEND_SUCCESS    : formatMessage({id: "com.email.sendSuccess",           defaultMessage: "성공적으로 전송했습니다."}),
        POPUP_GRANT_CHANGE_SUCCESS  : formatMessage({id: "com.email.grantChangeSuccess",    defaultMessage: "권한이 변경되었습니다."}),
        POPUP_EMAIL_SEND_FAIL       : formatMessage({id: "com.email.sendFail",              defaultMessage: "메일 전송에 실패했습니다."}),
        POPUP_MESSAGE_WRONG_TOKEN   : formatMessage({id: "com.popup.wrongToken",            defaultMessage: "잘못된 인증토큰 입니다."}),
        GRID_MESSAGE_NODATA         : formatMessage({id: "com.grid.noData",                 defaultMessage: "데이터 없음"}),
        SELECT_MESSAGE_FILTER       : formatMessage({id: "com.grid.filter",                 defaultMessage: "검색"}),
        SELECT_MESSAGE_MANYSELECTED : formatMessage({id: "com.grid.manySelected",           defaultMessage: "@(0)개 선택됨"}),
        SELECT_MESSAGE_ALL_SELECTED : formatMessage({id: "com.grid.allSelected",            defaultMessage: "전체"}),
        DATE_YEAR                   : formatMessage({id: "com.date.year",                   defaultMessage: "년"}),
        BUTTON_SAVE                 : formatMessage({id: "com.button.save",                 defaultMessage: "저장"}),
        BUTTON_CANCEL               : formatMessage({id: "com.button.cancel",               defaultMessage: "취소"}),
        BUTTON_MODIFY               : formatMessage({id: "com.button.modify",               defaultMessage: "수정"}),
        BUTTON_INITIALIZE           : formatMessage({id: "com.button.initialize",           defaultMessage: "초기화"}),
        BUTTON_SEARCH               : formatMessage({id: "com.button.search",               defaultMessage: "조회"}),
        POPUP_BUTTON_OK             : formatMessage({id: "com.popup.ok",                    defaultMessage: "확인"}),
        POPUP_BUTTON_CUSTOMER       : formatMessage({id: "com.popup.customer",              defaultMessage: "고객센터 접수"}),
        DAY_MON                     : formatMessage({id: "com.date.mon",                    defaultMessage: "월"}),
        DAY_TUE                     : formatMessage({id: "com.date.tue",                    defaultMessage: "화"}),
        DAY_WED                     : formatMessage({id: "com.date.wed",                    defaultMessage: "수"}),
        DAY_THU                     : formatMessage({id: "com.date.thu",                    defaultMessage: "목"}),
        DAY_FRI                     : formatMessage({id: "com.date.fri",                    defaultMessage: "금"}),
        DAY_SAT                     : formatMessage({id: "com.date.sat",                    defaultMessage: "토"}),
        DAY_SUN                     : formatMessage({id: "com.date.sun",                    defaultMessage: "일"}),
        GRID_NODATA_HTML_MSG1       : formatMessage({id: "com.grid.htmlMsg1",               defaultMessage: "검색 결과가 없습니다."}),
        GRID_NODATA_HTML_MSG2       : formatMessage({id: "com.grid.htmlMsg2",               defaultMessage: "다른 조회 조건"}),
        GRID_NODATA_HTML_MSG3       : formatMessage({id: "com.grid.htmlMsg3",               defaultMessage: "으로 검색해 보세요."}),
        ERROR_TIMEOUT_MESSAGE       : formatMessage({id: "com.error.timeout",               defaultMessage: "호텔로 해당 정보의 요청시간이 초과되었습니다.<br/>시스템관리자에게 문의바랍니다."}),
        ERROR_COMMON_MESSAGE        : formatMessage({id: "com.error.common",                defaultMessage: "Data 처리에 오류가 발생했습니다.<br/>시스템관리자에게 문의바랍니다."}),
        BUTTON_NEXT                 : formatMessage({id: "com.button.next",                 defaultMessage: "다음"}),
        BUTTON_PREV                 : formatMessage({id: "com.button.prev",                 defaultMessage: "이전"}),
        BUTTON_COMPLETE             : formatMessage({id: "com.button.complete",             defaultMessage: "완료"}),
        INPUT_DATA_USE              : formatMessage({id: "com.data.use",                    defaultMessage: "사용"}),
        INPUT_DATA_NOTUSED          : formatMessage({id: "com.data.notUsed",                defaultMessage: "사용안함"}),
        NOT_AUTHORITY               : formatMessage({id: "com.data.notAuthority",           defaultMessage: "해당 권한이 없습니다.<br/> 호텔 관리자에게 문의 바랍니다."}),
    }

    return MULTI_LANG;
};
