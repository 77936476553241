import React, { Fragment } from 'react';
import { Router } from 'react-router-dom';
import { Col } from 'reactstrap';
import {injectIntl} from "react-intl";

import icoWarning2 from '../../assets/utils/images/icon/ico-warning_2.png';
import {ERROR_LANG} from "./page_404_lang";

import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';

const PAGE_500 = (props) => {
    const {intl} = props;
    const {formatMessage} = intl;
    const parse = require('html-react-parser');
    const MULTI_LANG = ERROR_LANG(formatMessage);

    return (
        <Fragment>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Col className="mx-auto">
                            <div className="d-flex h-75 justify-content-center align-items-center">
                                <div className="warning text-center">
                                    <img src={icoWarning2} alt="warning icon" />
                                    <h5 className="menu3-header-title">
                                        {MULTI_LANG.ERROR_CONTENT1}
                                    </h5>
                                    <h6 className="menu3-header-subtitle mt-3">
                                        {MULTI_LANG.ERROR_CONTENT1_SUB1}
                                        <br />
                                        {parse(MULTI_LANG.ERROR_CONTENT1_SUB2)}
                                    </h6>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default injectIntl(PAGE_500);