import React, {Fragment, forwardRef, useImperativeHandle, useRef} from 'react';
import $ from 'jquery';

import {
    Button, Form, FormGroup,
    Card,
    CardBody,
    CardHeader,
    Col, CustomInput,
    InputGroup, InputGroupAddon,
    DropdownMenu,
    DropdownToggle, Label, Row,
    UncontrolledButtonDropdown,
    Input, Modal, ModalBody, ListGroup, ListGroupItem, Pagination,
} from "reactstrap";

import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactDOM from "react-dom";
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import Ionicon from "react-ionicons";
import {withRouter} from 'react-router-dom';
import Select from 'rc-select';
import dateFormat from 'dateformat';

import {multiLangReplace, nullToBlank, setGridHeight, stringDateFormatChange} from "../../../../utils/commUtils";

import {getCommonCode, postAxios, warningModalFunction} from "../../../../utils/commAxios";

import SEARCH_FILETER from "./searchFilter";
import {BootstrapAutoComplete} from "../../../../components/Autocomplete/Autocomplete";
import {ZZ12_0100_2_LANG} from './zz12_0100_2_lang';
import * as XLSX from 'xlsx';
import CertPopup from "../../../../popup/certPopup";
import {commonLang} from "../../../../utils/commonLang";
import {BootstrapDatePicker} from "../../../../components/ComponentsList";

import './zz12_0100_2.css';

import ZZ12_0100_2_Grid from './zz12_0100_2_Grid';
import { SearchButton } from '../../../../components/Button/Button';

let emailTypeList = [];
let emailAlertList = [];
let channelList = [];

let initCondition = [];
let initAddCondition = [];

let MULTI_LANG_2 = {};
let searchActive = false;

let historyGridApi = null;
let historyColumnApi = null;

let gridApi = null;
let gridColumnApi = null;

let edit = false;
let rowType = 0;

const CHANNEL_LIST_SEL_PACKAGE = "PG_ZZ12_0100.PR_TB_ZZ_CH_MASTER_SEL";
const EMAIL_CUSTOM_ALERT_SEL_PACKAGE = "PG_ZZ12_0100.PR_TB_ZZ_EMAIL_CUSTM_SET_SEL";
const typeCodeList = "EMAIL_SENDER_SET_CN,EMAIL_ALERT_CN"
// const emailTypeParams = {
//     names : [],
// }
// const emailAlertOptions = {
//     names : [],
// }
const emailTypeParams = {
    names : [],
}

const gridActiveParams = {
    names : [],
}

const emailAlertOptions = {
    names : [],
}

const channelListParams = {
    names : [],
}

let initSearchData = {};
let tempEmailType = [];
let tempChannelData = [];
let tempEmailAlert = [];
let optionEmailAlert = [];

const MAPPING_PARAMS = {
    START_DATE : 'DATA_LIST_SET_DATE',
    CN_NO : 'DATA_LIST_CN_NO',
    EMAIL : 'DATA_LIST_EMAIL',
    INCHARGE_NAME : 'DATA_LIST_INCHARGE_NAME',
    SEND_TYPE : 'DATA_LIST_SEND_TYPE',
}

const EXIST_PARAMS = {
    'DATA_LIST_SET_DATE'        : 'DATA_LIST_SET_DATE_HAVE_YN',
    'DATA_LIST_CN_NO'           : 'DATA_LIST_CN_NO_HAVE_YN',
    'DATA_LIST_EMAIL'           : 'DATA_LIST_EMAIL_HAVE_YN',
    'DATA_LIST_INCHARGE_NAME'   : 'DATA_LIST_INCHARGE_NAME_HAVE_YN',
    'DATA_LIST_SEND_TYPE'       : 'DATA_LIST_SEND_TYPE_HAVE_YN'
}

const ZZ12_0100_2 = (props) => {
    const {intl, history, PAGE_NO: pageNo, SYSTEM_MENU_NAME: systemMenuName, EXCEL_BTN: excelBtn, OPEN_BTN: openBtn, SAVE_BTN: saveBtn} = props;
    const {formatMessage} = intl;
    MULTI_LANG_2 = ZZ12_0100_2_LANG(formatMessage);
    const [initBtnActive, setInitBtnActive] = React.useState('N');

    const [activeOptions, setActiveOptions] = React.useState([
        {key:0, value: "", label: MULTI_LANG_2.OPTIONS_ALL},
        {key:1, value: "Y", label: MULTI_LANG_2.OPTIONS_USE},
        {key:2, value: "N", label: MULTI_LANG_2.OPTIONS_NO_USE},
    ])
    const [channelOptions, setChannelOptions] = React.useState([]);

    const [condition, setCondition] = React.useState([]);
    const [addCondition, setAddCondition] = React.useState([]);

    const [certPopup, setCertPopup] = React.useState(false);
    const [maskingYn, setMaskingYn] = React.useState('Y');

    let movePosition = {};
    let setPosition = {};
    const DATE_FORMAT = "yyyy-MM-dd";

    const [dataset, setDataset] = React.useState({
        'START_DATE'       : new Date(),
        'END_DATE'         : new Date(),
        'CN_NO'            : [],
        'ACTIVE_YN'        : 'Y',
        'EMAIL'            : "",
        'INCHARGE_NAME'    : "",
        'SEND_TYPE'        : []
    });

    React.useEffect(() => {
        ReactDOM.render(<SEARCH_FILETER condition={condition} addCondition={addCondition} locale={intl}
                                        onDetect={onDetectFunc}
                                        searchHandler={searchHandler}
                                        initCodition={initSearch}/>, document.getElementById('filterArea'));
    }, [condition]);

    const onDetectFunc = (setting, data, addCombo) => {
        let settingArr = setting.map((item, index) => {
            if(item.cd_comp === 'DatePeriod'){
                let tempKey = item.stateName.split(',');
                item.value = data[tempKey[0]]+ '~' + data[tempKey[1]];

                dataset[tempKey[0]] = new Date(data[tempKey[0]]);
                dataset[tempKey[1]] = new Date(data[tempKey[1]]);
            }else{
                item.value = data[item.stateName];

                dataset[item.stateName] = data[item.stateName];
            }

            return item;
        })

        setDataset({
            ...dataset,
            ...data
        });

        setCondition(settingArr);
        setAddCondition(addCombo);
    }

    const searchHandler = (event, popupYn) => {
        setInitBtnActive('N');

        let sendParams = {};

        for (let paramsKey in MAPPING_PARAMS) {
            for(let key in dataset) {
                let targetKey = key;

                if(key.indexOf(paramsKey) === 0){
                    targetKey = paramsKey;

                    if(nullToBlank(dataset[key]) !== ""){
                        if (!sendParams.hasOwnProperty(MAPPING_PARAMS[targetKey])) {
                            sendParams[MAPPING_PARAMS[targetKey]] = [];
                        }

                        if(paramsKey === 'START_DATE'){

                            let endKey = paramsKey.replace(paramsKey, 'END_DATE');
                            let tempPeriod = {
                                [paramsKey]  : nullToBlank(dataset[targetKey]) == "" ? "" : dataset[targetKey].format('yyyyMMdd'),
                                'END_DATE'   : nullToBlank(dataset[endKey]) == "" ? "" : dataset[endKey].format('yyyyMMdd')
                            };

                            console.log(nullToBlank(dataset[targetKey]));
                            console.log(nullToBlank(dataset[endKey]));
                            if (nullToBlank(dataset[targetKey]) != "" && nullToBlank(dataset[endKey]) != "") {
                                sendParams[MAPPING_PARAMS[paramsKey]].push(tempPeriod);
                            }
                        }else{
                            if(typeof sendParams[targetKey] === 'object' || sendParams[targetKey] instanceof Date){
                                sendParams[MAPPING_PARAMS[paramsKey]].push({[paramsKey] : dataset[targetKey].format('yyyyMMdd')});
                            }else if(targetKey === 'CN_NO'){
                                let tempCnNo = dataset[targetKey].map((item) => { return { CN_NO:item.value } });

                                sendParams[MAPPING_PARAMS[paramsKey]].push(...tempCnNo);
                            }else if(targetKey === 'SEND_TYPE'){
                                let tempCnNo = dataset[targetKey].map((item) => { return { SEND_TYPE:item.value } });

                                sendParams[MAPPING_PARAMS[paramsKey]].push(...tempCnNo);
                            }else{
                                sendParams[MAPPING_PARAMS[paramsKey]].push({[paramsKey] : dataset[targetKey]});
                            }
                        }
                    }
                }
            }
        }

        sendParams['DATA_LIST_SET_DATE_HAVE_YN'] = 'N';
        sendParams['DATA_LIST_CN_NO_HAVE_YN'] = 'N';
        sendParams['DATA_LIST_EMAIL_HAVE_YN'] = 'N';
        sendParams['DATA_LIST_INCHARGE_NAME_HAVE_YN'] = 'N';
        sendParams['DATA_LIST_SEND_TYPE_HAVE_YN'] = 'N';

        for (let key in EXIST_PARAMS){
            if(nullToBlank(sendParams[key]) !== ""){
                if(sendParams[key].length > 0){
                    sendParams[EXIST_PARAMS[key]] = 'Y';
                }
            }
        }

        sendParams['ACTIVE_YN'] = dataset.ACTIVE_YN

        searchJoinList(sendParams);
    }

    const searchJoinList = (sendParams) => {
        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'   : EMAIL_CUSTOM_ALERT_SEL_PACKAGE,
            ...sendParams
        }

        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            searchJoinCallback
            ,
            (e)=>{console.log(e)},
            sendParams,
            false
        );
    }

    const [rowData, setRowData] = React.useState([]);
    const searchJoinCallback = (response) => {
        const resultList = response.data.resultData.V_OUT_JAVA_CURSOR.map(v => {
            return {
                ...v,
                EMAIL_TEMP: v.EMAIL,
                // ROWTYPE: 0,
            }
        });

        setRowData([]);
        setRowData(resultList);
    }

    const cancelHandler = () => {
        searchHandler();
    }

    const initSearch = () => {
        setInitBtnActive('Y');
        setDataset({...initSearchData});
        setRowData([]);
    }

    React.useEffect(() => {
        initSearchData = {
            'START_DATE'       : new Date(),
            'END_DATE'         : new Date(),
            'CN_NO'            : [],
            'ACTIVE_YN'        : 'Y',
            'EMAIL'            : "",
            'INCHARGE_NAME'    : "",
            'SEND_TYPE'        : []
        }

        getCommonCode(typeCodeList, commCodeCallback);
    }, []);

    const commCodeCallback = (comnList) => {
        const emailTypeList = comnList['EMAIL_SENDER_SET_CN'];
        const emailAlertList = comnList['EMAIL_ALERT_CN'];

        emailTypeParams.names = [];
        emailAlertOptions.names = [];
        gridActiveParams.names = [];

        emailTypeList.forEach((item, index) => {
            emailTypeParams.names.push({
                key         : item.COMN_CODE,
                value       : item.COMN_CN,
                label       : item.COMN_CODE_NAME_MKEY,
            });
        });

        gridActiveParams.names.push({key:0, value: "Y", label: MULTI_LANG_2.OPTIONS_USE});
        gridActiveParams.names.push({key:1, value: "N", label: MULTI_LANG_2.OPTIONS_NO_USE});

        emailAlertList.forEach((item, index) => {
            emailAlertOptions.names.push({
                key         : item.COMN_CODE,
                value       : item.COMN_CN,
                label       : item.COMN_CODE_NAME_MKEY,
            });
        });


        optionEmailAlert = emailAlertOptions.names;

        getChannelList();
    };

    const getChannelList = () => {
        const dataString = {
            'PROCEDURE_OR_TABLE_NAME'       : CHANNEL_LIST_SEL_PACKAGE,
            'ACTIVE_YN'                     : 'Y'
        }

        postAxios({
                method: 'POST',
                url: "/comm/jsonProcess_Procedure"
            },
            dataString,
            (response) => {
                let channelData = response.data.resultData.V_OUT_JAVA_CURSOR;
                channelList = channelData;

                tempChannelData = channelData.map((item, index) => {
                    return {
                        key         : index,
                        value       : item.CN_NO,
                        label       : item.CN_NAME,
                    }
                })
                channelListParams.names = tempChannelData;

                setDataset({...initSearchData})
                setChannelOptions([
                    ...tempChannelData
                ]);

                initCondition = [
                    {key : 0,       cd_title : MULTI_LANG_2.SETTING_PERIOD,     cd_require : true,       cd_comp : "DatePeriod",      value : `${dataset.START_DATE}~${dataset.END_DATE}`,      dupl : true,  order : true, stateName : "START_DATE,END_DATE",      fix : true},
                    {key : 1,       cd_title : MULTI_LANG_2.BUSINESS_NAME,      cd_require : false,      cd_comp : "autoCombo",       value : [],                             dupl : false, order : true, stateName : "CN_NO",                    fix : true,  multi : true, combo_data : tempChannelData},
                    {key : 2,       cd_title : MULTI_LANG_2.ACTIVE_YN,          cd_require : false,      cd_comp : "radio",           value : dataset.ACTIVE_YN,                                dupl : false, order : true, stateName : "ACTIVE_YN",                fix : true,  combo_data : activeOptions},
                    {key : 3,       cd_title : MULTI_LANG_2.EMAIL_ADDRESS,          cd_require : false,      cd_comp : "text",           value : dataset.EMAIL,                                 dupl : false, order : true, stateName : "EMAIL",                    fix : true},
                    {key : 4,       cd_title : MULTI_LANG_2.CHARGE_NAME,          cd_require : false,      cd_comp : "text",           value : dataset.INCHARGE_NAME,                           dupl : false, order : true, stateName : "INCHARGE_NAME",            fix : true},
                    {key : 5,       cd_title : MULTI_LANG_2.SEND_TYPE,          cd_require : false,      cd_comp : "autoCombo",           value : [],                                           dupl : false, order : true, stateName : "SEND_TYPE",                fix : true,  multi : true, combo_data : optionEmailAlert},
                ];
                initAddCondition = [
                    //{label : MULTI_LANG_2.EMAIL_TYPE,           cd_comp : "singleCombo",        dupl : false,       stateName : "EMAIL_SENDER_SET_CN",         visible : true,     combo_data: tempEmailType},
                    // {label : MULTI_LANG_2.EMAIL_ADDRESS,        cd_comp : "text",               dupl : false,       stateName : "EMAIL",                       visible : true},
                    // {label : MULTI_LANG_2.CHARGE_NAME,          cd_comp : "text",               dupl : false,       stateName : "INCHARGE_NAME",               visible : true},
                    // {label : MULTI_LANG_2.SEND_TYPE,            cd_comp : "autoCombo",          dupl : false,       stateName : "SEND_TYPE",                   visible : true,     combo_data: optionEmailAlert,        multi : true},
                ];

                setAddCondition([...initAddCondition]);
                setCondition([...initCondition]);


                const startDate = dateFormat(dataset.START_DATE, 'yyyymmdd');
                const endDate = dateFormat(dataset.END_DATE, 'yyyymmdd');
                searchJoinList({
                    DATA_LIST_CN_NO: [],
                    ACTIVE_YN: dataset.ACTIVE_YN,
                    DATA_LIST_SET_DATE: [{"START_DATE": startDate,"END_DATE": endDate}],
                    DATA_LIST_SET_DATE_HAVE_YN : 'N',
                    DATA_LIST_CN_NO_HAVE_YN : 'N',
                    DATA_LIST_EMAIL_HAVE_YN : 'N',
                    DATA_LIST_INCHARGE_NAME_HAVE_YN : 'N',
                    DATA_LIST_SEND_TYPE_HAVE_YN : 'N',
                });
            },
            (e)=>{console.log(e)},
        );
    }

    React.useEffect(() => {
        $("#searchForm").off('keydown', 'input');
        $("#searchForm").on('keydown', 'input', (event) => {
            if(event.which === 13){
                const form = event.target.form;
                let index = Array.prototype.indexOf.call(form, event.target);

                if(nullToBlank(form.querySelectorAll('input')[index]) !== ""){
                    if(form.querySelectorAll('input')[index].getAttribute('type') === 'radio'){
                        index += form.querySelectorAll('input')[index].parentElement.parentElement.querySelectorAll('input').length;
                    }

                    if(nullToBlank(form.querySelectorAll('input')[index + 1]) === ""){
                        searchHandler();
                    }else{
                        if(form.querySelectorAll('input')[index + 1].getAttribute('type') === 'radio'){
                            let nextElement = parseInt(form.querySelectorAll('input')[index + 1].parentElement.parentElement.querySelectorAll('input:checked')[0].dataset.key) + 1;
                            form.querySelectorAll('input')[index + nextElement].focus();
                        }else{
                            form.querySelectorAll('input')[index + 1].focus();
                        }
                    }
                }else{
                    searchHandler();
                }

                event.preventDefault();
            }
        });

        makeFilterSetting();
    }, [dataset]);

    const makeFilterSetting = () => {
        let tempCondition = condition;

        tempCondition.map((item) => {
            if(item.cd_comp === "DatePeriod"){
                let tempKey = item.stateName.split(',');
                item.value = dataset[tempKey[0]]+ '~' + dataset[tempKey[1]];
            }else{
                item.value = dataset[item.stateName];
            }

            return item;
        });

        setCondition(tempCondition);
    }

    const handleStartChange = (value) => {
        setDataset({
            ...dataset,
            'START_DATE'    : value
        })
    }

    const handleEndChange = (value) => {
        setDataset({
            ...dataset,
            'END_DATE'    : value
        })
    }

    const checkChangeHandle = (event) => {
        let tempValue = event.target.value;
        let tempKey = event.target.name;

        setDataset({
            ...dataset,
            [tempKey]   : tempValue
        })
    }

    // const gridScrollHandler = (params) => {
    //     if($("#multiDropboxArea").html() !== ""){
    //         $("#multiDropboxArea").children('ul').css('top', setPosition.top - params.top + 1 + movePosition.top);
    //         $("#multiDropboxArea").children('ul').css('left', setPosition.left - params.left + movePosition.left);
    //     }
    // }

    const handleChangeCnNo = (value) => {
        setDataset({
            ...dataset,
            CN_NO   : value
        });
    }

    const isOpenCertPopup = () => {
        setCertPopup(true);
    }

    const certiCallBack = () => {
        setMaskingYn('N');
    }

    React.useEffect(() => {
        if(searchActive) {
            searchHandler(null,true);
        }else{
            searchActive = true;
        }
    }, [maskingYn]);

    //피커 포커스 아웃시 핸들러
    const onPickerBlurhandler = (searchName, position, compareName) => (event, autoDate, minFlag, maxFlag) => {
        if (autoDate === null) {
            if (searchName == 'startDate') {
                setDataset({
                    ...dataset,
                    START_DATE : null
                });
            } else {
                setDataset({
                    ...dataset,
                    END_DATE : null
                });
            }
            return;
        }

        if (minFlag || maxFlag) {
            setDataset({
                ...dataset,
                START_DATE : autoDate,
                END_DATE : autoDate
            });
        } else {
            if (searchName == 'startDate') {
                setDataset({
                    ...dataset,
                    START_DATE : autoDate
                });
            } else {
                setDataset({
                    ...dataset,
                    END_DATE : autoDate
                });
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if (event.target.name === 'startDatePicker') $('#endDatePicker').select();
            else if (event.target.name === 'endDatePicker') {
                setTimeout(() => {
                    $('#searchBtn').click();
                }, 100);
            }
        }
    }

    React.useEffect(() => {
        console.log('zz12_0100');
    }, []);

    return(
        <Fragment>
            <Row>
                <Col md="12">
                    <Form id="searchForm">
                        <FormGroup>
                            <Card className="mb-3 border-c3c9cd">
                                <CardBody className="pt-1">
                                    <div>
                                        <div className="d-md-inline-block mr-md-4 mt-3">
                                            <Label for="exampleText" className="font-weight-md text-333 mr-3">{MULTI_LANG_2.SETTING_PERIOD}</Label>
                                            <div className="d-md-inline-block">
                                                <InputGroup className="w_160_md">
                                                    <InputGroupAddon addonType="prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                        </div>
                                                    </InputGroupAddon>
                                                    <BootstrapDatePicker
                                                        id="startDatePicker"
                                                        dateClass={'form-control'}
                                                        value={dataset.START_DATE}
                                                        onBlurFunc={onPickerBlurhandler('startDate', 'from', 'endDate')}
                                                        dateFormat={DATE_FORMAT}
                                                        compareMaxValue={dataset.END_DATE}
                                                        preventOpenOnFocus={true}
                                                        onKeyDownFunc={handleKeyDown}
                                                        name="startDatePicker"
                                                        onChangeFunc={handleStartChange}
                                                    />
                                                </InputGroup>
                                            </div>
                                            <span className="ml-2 mr-2">~</span>
                                            <div className="d-md-inline-block">
                                                <InputGroup className="w_160_md">
                                                    <InputGroupAddon addonType="prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                        </div>
                                                    </InputGroupAddon>
                                                    <BootstrapDatePicker
                                                        id="endDatePicker"
                                                        dateClass={'form-control'}
                                                        value={dataset.END_DATE}
                                                        onBlurFunc={onPickerBlurhandler('endDate', 'to', 'startDate')}
                                                        dateFormat={DATE_FORMAT}
                                                        compareMinValue={dataset.START_DATE}
                                                        preventOpenOnFocus={true}
                                                        onKeyDownFunc={handleKeyDown}
                                                        name="endDatePicker"
                                                        onChangeFunc={handleEndChange}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>
                                        <span className="ml-2 mr-4 d-none d-md-inline-block text-e9ecef3">|</span>
                                        <div className="d-md-inline-block mr-md-4 mt-3">
                                            <Label for="exampleText" className="font-weight-md text-333">{MULTI_LANG_2.BUSINESS_NAME}</Label>
                                            <div className="badge badge-dot badge-dot-sm badge-danger mr-3 ml-1 top-5">
                                                {MULTI_LANG_2.PRIMARY}
                                            </div>
                                            <BootstrapAutoComplete
                                                id="cnNo"
                                                options={channelOptions}
                                                selectList={dataset.CN_NO}
                                                onChangeFunc={handleChangeCnNo}
                                                multiple={true}
                                                includeSelectAll={true}
                                                includeFilter={true}
                                                placeholder=""
                                                keepOpen={false}
                                                className={"w_250_md d-md-inline-block align-bottom"}
                                                selectAllText={MULTI_LANG_2.OPTIONS_ALL}
                                            />
                                        </div>
                                        <span className="ml-2 mr-4 d-none d-md-inline-block text-e9ecef3">|</span>
                                        <div className="d-md-inline-block mt-3">
                                            <Label className="font-weight-md text-333">{MULTI_LANG_2.ACTIVE_YN}</Label>
                                            <div className="badge badge-dot badge-dot-sm badge-danger mr-3 ml-1 top-5">
                                                {MULTI_LANG_2.PRIMARY}
                                            </div>
                                            {
                                                activeOptions.map((item, index) => (
                                                    <CustomInput type="radio" id={"activeYn_"+ index} name="ACTIVE_YN" label={item.label} value={item.value} className="custom-control-inline font-size-082" checked={(item.value===dataset.ACTIVE_YN) ? true : false} onClick={checkChangeHandle}/>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="divider mt-0-8rem mb-0-8rem" />
                                    <div className="text-center">
                                        <SearchButton initHandler = {initSearch} searchHandler = {searchHandler} searchId='searchBtn' close={openBtn}/>
                                        {/* <Button className="mr-2 btn-icon font-weight-md w90" color="light" onClick={initSearch}>
                                            <i className="pe-7s-refresh btn-icon-wrapper font-weight-bold"> </i>
                                            {MULTI_LANG_2.INITIALIZE}
                                        </Button>
                                        <Button id="searchBtn" className="btn-icon font-weight-md text-white w90" color="primary" onClick={searchHandler}>
                                            <i className="pe-7s-search btn-icon-wrapper font-weight-bold"> </i>
                                            {MULTI_LANG_2.SEARCH}
                                        </Button> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>

            <ZZ12_0100_2_Grid
                rowData={rowData}
                initBtn={initBtnActive}
                setInitBtn={setInitBtnActive}
                channelListParams={channelListParams}
                emailTypeParams={emailTypeParams}
                emailAlertOptions={emailAlertOptions}
                gridActiveParams={gridActiveParams}
                cancelHandler={cancelHandler}
                searchHandler={searchHandler}
                pageNo={pageNo}
                systemMenuName={systemMenuName}
                excelBtn={excelBtn}
                maskingYn={maskingYn}
                isOpenCertPopup={isOpenCertPopup}
                saveBtn={saveBtn}
            />

            <CertPopup show={certPopup} setShow={setCertPopup} callbackFunc={certiCallBack}/>
            <div id="multiDropboxArea"></div>
        </Fragment>
    )
}

export default withRouter(ZZ12_0100_2);

export const showWarningPopup = (targetName, targetField) => {
    warningModalFunction(commonLang(), targetName + MULTI_LANG_2.INPUT_CHECK, ()=>{
        try {
            document.getElementById(targetField).focus();
        }catch(e){}
    });
}
