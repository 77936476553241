/**
 * 파일명       : zz10_0301_SearchBar.js
 * 파일 설명	: 호텔 계약 리스트 그리드 영역
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0  설대형 2020.01.03  최초 작성
*/

import React, {useState, useRef} from "react";
import {injectIntl} from "react-intl";
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import {connect} from "react-redux";
import {SaveButton} from "components/Button/Button";
import AutoComplate from "./zz10_0301_AutoComplate";
import {joinFreeChannelByString, splitFreeChannel} from "../zz10_0301";
import {ZZ10_0301_LANG} from "../zz10_0301_leng";
import {GridSelect} from "../../../../components/Select/Select";
import {Pagination} from "../../../../components/ComponentsList";
import {commonRequest, getCommonCode, warningModalFunction} from "../../../../utils/commAxios";
import {commonLang} from "../../../../utils/commonLang";
import {nullToBlank, setGridHeight, stringDateFormatChange, numberWithCommas, multiLangReplace} from "../../../../utils/commUtils";
import CertPopup from "../../../../popup/certPopup";
import AgGridEdit from "../../../../components/AgGrid/AgGridEdit";
import {NumberCellEditor, GridDateFieldCellEditor, ExcelButton} from "../../../../components/ComponentsList";
import {CertButton} from "../../../../components/Button/Button";
import {CriticalColumnHeader} from "../../../../components/AgGrid/components/AggridComponents";
import {UPDATE_STATUS, EXCEL_DEFAULT_STYLES} from "../../../../utils/globalValue";
import "./zz10_0301_ContractList.css";

let area = {names:[]};
let contPeriod = {names:[]};
let contCondition = {names:[]};
let freeChannel = {names:[]};

const ZZ10_0301_ContractList = ({
    pageNo,
    intl,
    contracts,
    enableClosedSidebar,
    onSave,
    onCancle,
    onPageMove,
    onMaskingYnChange,
    page,
    totalRowcount,
    maskingYn,
    initBtn,
    setInitBtn,
    savePermission,
    excelPermission,
    systemMenuName
}) => {
    const {formatMessage} = intl;
    const MULTI_LANG = ZZ10_0301_LANG(formatMessage);

    const PACKAGE_CHANNEL_SEL = "PG_ZZ00_COMN_COMBO_LIST.PR_CH_CHANNEL_LIST_SEL";
    const COMMON_LIST = "CMS_CONT_PERIOD_CN,CMS_CONT_CONDITION_CN,REGION_CN";
    const [gridApi, setGridApi] = useState();

    /**
     * @author DaeHyung Seol
     * @description 화면 로딩시 공통코드 조회
    */
    React.useEffect(()=>{
        getCommonCode(
            COMMON_LIST,
            value => {
                contPeriod.names = value.CMS_CONT_PERIOD_CN.map((data, index) => ({
                    key: index,
                    value: data.COMN_CN,
                    label: data.COMN_CODE_NAME_MKEY
                }));

                contCondition.names = value.CMS_CONT_CONDITION_CN.map((data, index) => ({
                    key: index,
                    value: data.COMN_CN,
                    label: data.COMN_CODE_NAME_MKEY
                }));

                area.names = value.REGION_CN.map((data, index)=>({
                    key: index,
                    value: data.COMN_CN,
                    label: data.COMN_CODE_NAME_MKEY
                }));
            }
        );

        const form = {
            PROCEDURE_OR_TABLE_NAME: PACKAGE_CHANNEL_SEL,
            ACTIVE_YN : 'Y'
        }

        commonRequest(result => {
            const items = result.data.resultData.V_OUT_JAVA_CURSOR;

            freeChannel.names=items.map(item => ({
                key: item.CN_NO,
                value: item.CN_NO,
                label: item.CN_NAME
            }));
        }, form);
    }, []);

    const [certPopup, setCertPopup] = useState(false);
    const [gridContract, setGridContract] = React.useState({
        columnDefs: [{
                field: 'companyId',
                headerName: MULTI_LANG.GRID_HEADER_COMPANY_ID,
                cellStyle: {textAlign: 'left', justifyContent: 'flex-start'},
                width: 150,
                editable: false,
                suppressNavigable: true,
                cellClass: ['excel-left-align', 'excel-string']
            }, {
                field: 'propertyName',
                headerName: MULTI_LANG.GRID_HEADER_PROPERTY_NAME,
                cellStyle: { textAlign: 'left', justifyContent: 'flex-start' },
                width: 200,
                editable: false,
                suppressNavigable: true,
                cellClass: ['excel-left-align', 'excel-string']
            }, {
                field: 'area',
                headerName: MULTI_LANG.GRID_HEADER_AREA,
                cellStyle: { textAlign: 'center', justifyContent: 'flex-start' },
                valueFormatter: params => {
                    let value = {
                        key: null,
                        value: null,
                        label: null
                    };

                    if (!params.value) return value.label;

                    value = params.context.area.names.find(child => child.value === parseInt(params.value));

                    return params.context.area.names.length === 0 ? params.data.areaName : value.label;
                },
                cellEditor: 'SelectCellRenderer',
                cellEditorParams: area,
                codeName: 'areaName',
                cellClass: ['excel-left-align', 'excel-string']
            }, {
                field: 'channelStartDate',
                headerName: MULTI_LANG.GRID_HEADER_CH_START_DATE,
                cellStyle: {textAlign: 'center'},
                editable: false,
                valueFormatter: params => stringDateFormatChange(params.value),
                cellClass: ['excel-center-align', 'jh-excel-date']
            }, {
                field: 'channelEndDate',
                headerName: MULTI_LANG.GRID_HEADER_CH_END_DATE,
                cellStyle: {textAlign: 'center'},
                editable: false,
                valueFormatter: params => stringDateFormatChange(params.value),
                cellClass: ['excel-center-align', 'jh-excel-date']
            }, {
                field: 'pms',
                headerName: MULTI_LANG.GRID_HEADER_PMS,
                cellStyle: {textAlign: 'center'},
                editable: false,
                cellClass: ['excel-center-align', 'excel-string']
            }, {
                field: 'contrackManager',
                headerName: MULTI_LANG.GRID_HEADER_CONTRACT_MANAGER,
                cellStyle: {textAlign: 'left', justifyContent: 'flex-start'},
                cellClass: ['excel-left-align', 'excel-string']
            }, {
                field: 'conditions',
                headerName: MULTI_LANG.GRID_HEADER_CONDITION,
                cellStyle: {textAlign: 'center'},
                valueFormatter: params => {
                    let value = {key: null, value: null, label: null};

                    if (!params.value) return value.label;

                    value = params.context.contCondition.names.find(child => child.value === parseInt(params.value));

                    return params.context.contCondition.names.length === 0 ? params.data.conditionsName : value.label;
                },
                cellEditor: 'SelectCellRenderer',
                cellEditorParams: contCondition,
                codeName: 'conditionsName',
                cellClass: ['excel-center-align', 'excel-string']
            }, {
                field: 'freeChannel',
                headerName: MULTI_LANG.GRID_HEADER_FREE_CH,
                width: 300,
                cellRenderer: 'AutocomplateCell',
                cellEditor: 'AutoComplateCellEditor',
                cellEditorParams: freeChannel,
                suppressNavigable: true,
                cellClass: ['excel-left-align', 'excel-string', 'excel-array-v1']
            }, {
                field: "channelNum",
                headerName: MULTI_LANG.GRID_HEADER_CH_CNT,
                cellStyle: {textAlign: 'right', justifyContent: 'flex-start'},
                headerComponent: 'headerRender',
                width: 130,
                valueFormatter: params => numberWithCommas(params.value),
                cellEditor: 'NumberCellEditor',
                cellEditorParams: {
                    inputProps: {
                        maxlength: 6
                    }
                },
                cellClass: ['excel-right-align', 'excel-number']
            }, {
                field: 'contractAdd',
                headerName: MULTI_LANG.GRID_HEADER_ADDITION_CONTRACT,
                cellStyle: {textAlign: 'right', justifyContent: 'flex-start'},
                editable: false,
                width: 130,
                valueFormatter: params => numberWithCommas(params.value),
                cellClass: ['excel-right-align', 'excel-number']
            }, {
                field: 'linkageChannelNum',
                headerName: MULTI_LANG.GRID_HEADER_LINKAGE_CH_CNT,
                cellStyle: {textAlign: 'right', justifyContent: 'flex-start'},
                width: 130,
                editable: false,
                valueFormatter: params => numberWithCommas(params.value),
                cellClass: ['excel-right-align', 'excel-number']
            }, {
                field: 'usageStatus',
                headerName: MULTI_LANG.GRID_HEADER_USAGE_STATUS,
                cellStyle: {textAlign: 'center'},
                headerComponent: 'headerRender',
                width: 130,
                valueFormatter: params => (params.value === 'Y') ? MULTI_LANG.GRID_SELECT_ITEM_Y : MULTI_LANG.GRID_SELECT_ITEM_N,
                cellEditor: 'SelectCellRenderer',
                cellEditorParams: {
                    names: [
                        {key: 0, value: 'Y', label: MULTI_LANG.GRID_SELECT_ITEM_Y},
                        {key: 1, value: 'N', label: MULTI_LANG.GRID_SELECT_ITEM_N}
                    ]
                },
                cellClass: ['excel-center-align', 'excel-string'],
                codeName: 'usageStatusName'
            }, {
                field: 'actualStatus',
                headerName: MULTI_LANG.GRID_HEADER_ACTIVE,
                cellStyle: {textAlign: 'center'},
                headerComponent: 'headerRender',
                width: 120,
                valueFormatter: params => (params.value === 'Y') ? MULTI_LANG.GRID_SELECT_ITEM_Y : MULTI_LANG.GRID_SELECT_ITEM_N,
                cellEditor: 'SelectCellRenderer',
                cellEditorParams: {
                    names: [
                        {key: 0, value: 'Y', label: MULTI_LANG.GRID_SELECT_ITEM_Y},
                        {key: 1, value: 'N', label: MULTI_LANG.GRID_SELECT_ITEM_N}
                    ]
                },
                cellClass: ['excel-center-align', 'excel-string'],
                codeName: 'actualStatusName'
            }, {
                field: 'areaName',
                hide: true
            }, {
                field: 'termName',
                hide: true
            }, {
                field: 'conditionsName',
                hide: true
            }
        ],
        defaultColDef: {
            sortable: true,
            editable: true,
            resizable: true,
            headerClass: 'text-center',
            menuTabs: false
        },
        rowData: [],
        frameworkComponents: {
            SelectCellRenderer: GridSelect,
            headerRender: CriticalColumnHeader,
            AutoComplateCellEditor: AutoComplate,
            AutocomplateCell: AutoComplateCellRenderer,
            DateField: GridDateFieldCellEditor,
            NumberCellEditor: NumberCellEditor
        },
        domLayout: 'autoHeight'
    });

    const mounted = useRef(false);

    /**
     * @author DaeHyung Seol
     * @description 부모 화면 계약 리스트 데이터 변경 시 그리드 데이터 랜더링
    */
    React.useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!gridApi) return;

            setGridContract({...gridContract, rowData: [...contracts]});
        }
    }, [contracts]);

    /**
     * @author DaeHyung Seol
     * @description 사이드 메뉴 OPEN/CLOSE 시 실행
    */
    React.useEffect(() => {
        if (nullToBlank(gridApi) !== '') {
            gridApi.headerRootComp.eGui.style.maxWidth = '';
            setGridHeight(gridApi);
        }
    }, [enableClosedSidebar]);

    /**
     * @author DaeHyung Seol
     * @description 그리스 초기화
     * @param {Object} params
    */
    const onGridReady = params => {
        setGridApi(params.api);
        setGridContract({...gridContract, rowData: [...contracts]});
    }

    /**
     * @author DaeHyung Seol
     * @description 호텔 계약 리스트 저장
    */
    const handleSaveContracts = () => {
        gridApi.stopEditing();
        const nodeUpdated = gridApi.getRenderedNodes().filter(node => String(node.data.ROWTYPE) === UPDATE_STATUS);

        if (isInvalidationChangedValue(nodeUpdated)) return false;

        const masters = [];
        const masterFrees = [];

        nodeUpdated.forEach(({data: contract}) => {
            masters.push({
                PM_NO: contract.pmNo,
                REGION_CN: contract.area,
                BEGIN_REQ_DATE: contract.channelRequestDate,
                CMS_CONT_PERIOD_CN: contract.term,
                CONT_INCHARGE: contract.contrackManager,
                CMS_CONT_CONDITION_CN: contract.conditions,
                CONT_CN_CNT: contract.channelNum,
                CONT_STATUS_YN: contract.usageStatus,
                ACTIVE_YN: contract.actualStatus
            });

            const freeChannels = splitFreeChannel(contract.freeChannel);

            freeChannels.forEach(freeItem => {
                masterFrees.push({
                    PM_NO: contract.pmNo,
                    CN_NO: freeItem.value
                });
            });
        });

        let params = {};

        if (masters.length) params = {...params, DATA_LIST_TB_PM_MASTER: masters};
        if (masterFrees.length) params = {...params, DATA_LIST_TB_PM_CN_FREE: masterFrees};

        onSave({...params});
    };

    /**
     * @author DaeHyung Seol
     * @description 인증 콜백
    */
    const certiCallBack = () => {
        onMaskingYnChange('N');
    }

    /**
     * @author DaeHyung Seol
     * @description 인증 팝업 OPEN
    */
    const isOpenCertPopup = () => {
        setCertPopup(true);
    }

    /**
     * @author DaeHyung Seol
     * @description AgGrid 자동으로 조절
     * @param {Object} params AgGrid 파라미터
    */
    const autoAllColumnSize = params => {
        const colIds = params.columnApi.getAllDisplayedColumns().filter(col => (col.colDef.width === undefined)).map(col => col.getColId());

        params.columnApi.autoSizeColumns(colIds);
    }

    /**
     * @author DaeHyung Seol
     * @description 유효성 검사
     * @param {Object} updatedContracts 변경된 데이터
     * @return {boolean}
    */
    const isInvalidationChangedValue = updatedContracts => {
        const keys = [
            "usageStatus",
            "actualStatus"
        ];
        
        for (let i = 0; i < updatedContracts.length; i++) {
            const contract = updatedContracts[i].data;

            const invalidField = keys.filter(key => {
                if (!contract[key]) return true;
            });

            if (invalidField.length > 0) {
                const headerName = gridApi.getColumnDef(invalidField[0]).headerName;

                gridApi.clearFocusedCell();
                gridApi.setFocusedCell(updatedContracts[i].rowIndex, invalidField[0]);
                gridApi.startEditingCell({
                    rowIndex: updatedContracts[i].rowIndex,
                    colKey: invalidField[0]
                });

                warningModalFunction(commonLang(), multiLangReplace(MULTI_LANG.POPUP_MESSAGE_VALID, [headerName]));

                return true;
            }
        }

        return false;
    }

    return (
        <>
            <Row>
                <Col md="12">
                    <div style={{marginBottom: '40px'}}>
                        <Card>
                            <CardHeader className="card-header-tab">
                                <div className="btn-actions-pane-right">
                                    <ExcelButton
                                        pageNo={pageNo}
                                        gridApi={gridApi}
                                        pageName={systemMenuName}
                                        isNotPerm={excelPermission}
                                    />
                                    <CertButton
                                        maskingYn={maskingYn}
                                        onClick={isOpenCertPopup}
                                        dateLength={gridContract.rowData.length}
                                    />
                                </div>
                            </CardHeader>
                            <CardBody id="gridArea" className="data-table">
                                <div
                                    id="zz10_0301_cont__grid"
                                    className="ag-theme-balham"
                                    style={{height: '100%', width: '100%'}}
                                >
                                    <AgGridEdit
                                        columnDefs={gridContract.columnDefs}
                                        defaultColDef={gridContract.defaultColDef}
                                        rowData={gridContract.rowData}
                                        onGridReady={onGridReady}
                                        frameworkComponents={gridContract.frameworkComponents}
                                        components={gridContract.components}
                                        headerHeight={59}
                                        localeText={{noRowsToShow: commonLang().GRID_MESSAGE_NODATA}}
                                        domLayout={gridContract.domLayout}
                                        onRowDataChanged={params => {
                                            setGridHeight(params.api);
                                            autoAllColumnSize(params);
                                        }}
                                        onGridSizeChanged={params => {
                                            setGridHeight(params.api);
                                            autoAllColumnSize(params);
                                        }}
                                        context={(() => ({
                                            area: area,
                                            contPeriod: contPeriod,
                                            contCondition: contCondition,
                                            freeChannel: freeChannel
                                        }))()}
                                        headerFixed={true}
                                        initBtn={initBtn}
                                        setInitBtn={setInitBtn}
                                        excelStyles={EXCEL_DEFAULT_STYLES}
                                        rowBuffer={500}
                                    />
                                </div>
                            </CardBody>
                            <div id="autoComplateClone" />
                        </Card>
                    </div>
                </Col>
            </Row>
            <div className="app-footer fixFooter">
                <div className="app-footer__inner">
                    <div className="paging">
                        <Pagination
                            showSizeChanger
                            defaultCurrent={1}
                            onChange={(page, pageSize) => {
                                onPageMove(page, pageSize, 'N');
                            }}
                            onShowSizeChange={(page, pageSize) => {
                                onPageMove(1, pageSize, 'N');
                            }}
                            total={parseInt(totalRowcount)}
                            current={parseInt(page.page)}
                            locale={intl}
                        />
                    </div>
                    <SaveButton
                        cancelHandler={onCancle}
                        saveHandler={handleSaveContracts}
                        close={savePermission}
                    />
                </div>
            </div>
            <CertPopup
                show={certPopup}
                setShow={setCertPopup}
                callbackFunc={certiCallBack}
            />
        </>
    );

    /**
     * @author DaeHyung Seol
     * @description 무료채널에 리스트를 문자열로 표시하는 AgGrid CellRenderer
     * @param {Object} params AgGrid 파라미터
     * @return {Component}
    */
    function AutoComplateCellRenderer(params) {
        const htmlValue = joinFreeChannelByString(params.value);

        return (
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                {htmlValue}
            </div>
        );
    }
}

/**
 * @author DaeHyung Seol
 * @description 프로퍼티 정보, 사이드 메뉴 OPEN/CLOSE 반환 함수
 * @param {Object} state
 * @return {Object}
*/
const mapStateToProps = state => ({
    propertyNo: state.PropertyState.propertyNo,
    propertyName: state.PropertyState.propertyName,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar
});

export default injectIntl(connect(mapStateToProps)(ZZ10_0301_ContractList));