import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Icons } from "../../components/Icons/Icons";

import PopupOneBtn from "./DynamicPopup/components/PopupOneBtn"
import PopupTwoBtn from "./DynamicPopup/components/PopupTwoBtn"
import {PopupThreeBtn, FontPopupThreeBtn} from "./DynamicPopup/components/PopupThreeBtn"

const ConfirmAlert = ({classes, ...props}) => {
    let {onOpen, onClose, title, content, agreeLabel, disagreeLabel} = props;
    content = {__html: content};

    const handleClose = (param) => {
        onClose(param);
    }

    return (
        <div>
            <Dialog
                open={onOpen}
                onClose={handleClose}
                disableBackdropClick={true}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <React.Fragment >
                        <div dangerouslySetInnerHTML={content}>
                        </div>
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose('yes')} color="primary" autoFocus>
                        {agreeLabel}
                    </Button>
                    <Button onClick={() => handleClose('no')} color="primary">
                        {disagreeLabel}
                    </Button>
                </DialogActions>
            </Dialog> 
        </div>
    )
}


const ErrorAlert = ({classes, ...props}) => {
    let {onOpen, onClose, title, content, agreeLabel, } = props;
    content = {__html: content};

    const handleClose = () => {
        onClose();
    }
    
    return (
        <div>
            <Dialog
                open={onOpen}
                onClose={handleClose}
                disableBackdropClick={true}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <React.Fragment >
                        <div dangerouslySetInnerHTML={content}>
                        </div>
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary" autoFocus>
                        {agreeLabel}
                    </Button>
                </DialogActions>
            </Dialog> 
        </div>
    )
}


const WarningAlert = ({classes, ...props}) => {
    let {onOpen, onClose, title, content, agreeLabel, } = props;
    content = {__html: content};

    const handleClose = () => {
        onClose();
    }
    
    return (
        <div>
            <Dialog
                open={onOpen}
                onClose={handleClose}
                disableBackdropClick={true}
            >
                <DialogTitle onClose={handleClose}>{title}</DialogTitle>
                <DialogContent>
                    <React.Fragment >
                        <div dangerouslySetInnerHTML={content}>
                        </div>
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary" autoFocus>
                        {agreeLabel}
                    </Button>
                </DialogActions>
            </Dialog> 
        </div>
    )
}

const CloseWindowConfirmAlert = ({classes, ...props}) => {
    let {title, buttonName, onSave, onClose, state, setState, childrenDialogContent: ChildrenDialogContent, childrenDialogActions: ChildrenDialogActions, } = props;

    const [displayOpen, setDisplayOpen] = React.useState(false);
    const handleOpen = () => {
        setDisplayOpen(true);
    }
    const handleClose = () => {
        setDisplayOpen(false);

        if ( onClose !== undefined ) {
            onClose();
        }
    }
    
    return (
        <div>
            <Button variant="contained" 
                    color="secondary" 
                    size="large" 
                    onClick={handleOpen} > 
                {buttonName}
            </Button>
            <Dialog 
                open={displayOpen}
                onClose={handleClose}
                disableBackdropClick={true}
            >
                <DialogTitleClose onClose={handleClose} >{title} </DialogTitleClose>
                <DialogContent dividers>
                    <ChildrenDialogContent setState={setState} state={state}/>
                </DialogContent>
                <DialogActions>
                    <ChildrenDialogActions onClose={handleClose} onSave={onSave} state={state} setState={setState} />
                </DialogActions>

            </Dialog> 
        </div>
    )
}

const stylesButton = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
const DialogTitleClose = withStyles(stylesButton)(props => {
    const { children, classes, onClose } = props;
    return (
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Icons tag="CloseIcon" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
});

export {ConfirmAlert, ErrorAlert, WarningAlert, CloseWindowConfirmAlert, DialogTitleClose, PopupOneBtn, PopupTwoBtn, PopupThreeBtn, FontPopupThreeBtn}