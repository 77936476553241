/**
 * 파일명       : DownloadButton.js
 * 파일 설명	: 다운로드 공통 버튼
 * 시스템 구분	: ZZ
 *
 * Ver 작성자  일자         내용         비고
 * --- ------ ---------- ---------- --------------------------
 * 1.0 설대형 2019.11.14  최초 작성
*/

/*

        POPUP_HEADER_SUCCESS        : formatMessage({id: "com.popup.success", defaultMessage: "성공"}),
        POPUP_HEADER_WARNING        : formatMessage({id: "com.popup.warning", defaultMessage: "경고"}),
        POPUP_HEADER_ERROR          : formatMessage({id: "com.popup.error", defaultMessage: "에러"}),
        POPUP_EMAIL_SEND_SUCCESS    : formatMessage({id: "com.email.sendSuccess", defaultMessage: "성공적으로 전송했습니다."}),
        POPUP_EMAIL_SEND_FAIL       : formatMessage({id: "com.email.sendFail", defaultMessage: "메일 전송에 실패했습니다."}),
*/

import React, {useState, useEffect, useRef} from 'react'
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import dateFormat from 'dateformat';
import $ from 'jquery';

import {
    Button,
    FormGroup, Label,
    Input,
    Col,
    Modal, ModalBody, ModalFooter
} from 'reactstrap';

import parse from 'html-react-parser';
import { commonRequest, postAxios, successModalFunction, warningModalFunction, personalApiReq} from '../../utils/commAxios';
import { commonLang } from '../../utils/commonLang';
import {getSessionInfo, nullToBlank, stringDateFormatChange} from '../../utils/commUtils';
import { DOWNLOAD_REASON_POPUP_LANG } from './DownloadReasonPopup_lang';
import { sendTypeFormatter } from '../zz/zz12/zz12_0100/zz12_0100_2_Grid';
import { joinFreeChannelByString } from '../zz/zz10/zz10_0301';

export const EXCEL_DOWNLOAD_OPERATION_CN = 383300;

const GridExcelDownloadButton = injectIntl(({pageNo, gridApi, isNotPerm=false, pageName="업무명", addProperty={}, intl, prsnInfo, apiInfo}) => {
    const {formatMessage} = intl;
    const MULTI_LANG = DOWNLOAD_REASON_POPUP_LANG(formatMessage);
    
    // 383300	382100	2	엑셀다운로더	2	Y	N											19/09/25	0	0	19/09/25	0	0	450200	344400



    const [downloadModalOpen, setDownloadModalOpen] = useState(false);


    const handleTogglePopupDownload = () => {
        if (isNotPerm) {
            warningModalFunction(commonLang(), parse(commonLang().NOT_AUTHORITY));
            return;
        }

        if (gridApi === null) return;

        if (gridApi.getDisplayedRowCount() === 0) {
            warningModalFunction(commonLang(), MULTI_LANG.POPUP_ALERT_NO_GRID_DATA);
            return;
        }

        let warningFlag = false;
        
        gridApi.forEachNode((node, index) => {
            if (node.data.ROWTYPE == "1" || node.data.ROWTYPE == "2") {
                warningFlag = true;
            }
        });

        const editingGrid = gridApi.getEditingCells().length > 0 ? true : false;

        if (editingGrid || warningFlag) {
            setTimeout(function() {
                warningModalFunction(commonLang(), MULTI_LANG.POPUP_MESSAGE_MODIFYDATA);
                        
            }, 100);
        } else {
            setDownloadModalOpen( !downloadModalOpen );
        }
    }

    return (
        <>
            <Button className="mr-2 btn-icon btn-icon-onlys" color="excel" onClick={handleTogglePopupDownload}>
                <i className="pe-7s-excel"></i>
            </Button>

            <DownloadReasonPopup 
                pageNo={pageNo} 
                open={downloadModalOpen} 
                onOpen={setDownloadModalOpen}
                userOperationCn={EXCEL_DOWNLOAD_OPERATION_CN} 
                onSuccess={exportExcel(gridApi, pageName, addProperty)}
                prsnInfo={prsnInfo}
                apiInfo={apiInfo}
            />
        </>
    );
});


const DownloadReasonPopup =injectIntl( ({intl, pageNo, userOperationCn, open, onSuccess, onOpen, prsnInfo=null, apiInfo=null}) => {
    const {formatMessage} = intl;
    const MULTI_LANG = DOWNLOAD_REASON_POPUP_LANG(formatMessage);
    const initDownloadField = {
        fields: {},
        errors: {},
    };
    const [userNo, setUserNo] = useState(null);
    const [userName, setUserName] = useState('');
    const [pmNo, setPmNo]    = useState(null);

    useEffect(() => {
        const userSession = getSessionInfo();
        setUserNo(userSession.SS_USER_NO);
        setUserName(userSession.SS_USER_FIRST_NAME);
        setPmNo(userSession.SS_PMS_NO);
    }, [] );

    // let procReasonInputEl = useRef();
    // let procReasonInputEl = ;

    const [downloadField, setDownloadField] = useState(initDownloadField);
    const handleChangeDownloadField = (field) => event => {
        setDownloadField({ ...downloadField,
            fields: { ...downloadField.fields,
                [field]: event.target.value,
            }
        });
    };

    useEffect(()=>{
        if (!open) return;
        setTimeout(()=>$('#userLogGrid__procReason').focus(), 200);
    }, [open])

    // 다운로드 팝업을 열때 userNo 을 제외한 데이터를 초기화한다.
    /**
     * TODO
     *  - userNo 에서 userName 으로 변경 ( 세션작업이 끝나면 진행... ) [완료]
     */
    useEffect(()=>{
        if (open) {
            setDownloadField({ ...initDownloadField,
                fields: { userName: userName }
            });
        }
    }, [open]);


    const handleExportExcel = () => {
        if ( !isVaildDownloadField() ) return;
        async_sendProgramLog({
            PM_NO               : pmNo+"",
            USER_NO             : userNo+"",
            PAGE_NO             : pageNo+"",
            USER_OPERATION_CN   : userOperationCn+"",
            PROC_DT             : dateFormat(new Date(), "yyyymmddHHMMss"),
            PROC_REASON         : downloadField.fields.procReason,
            // REF_COMT            : document.querySelector('#userLogGrid__download_reason input,textarea').value,
        });
    }

    const isVaildDownloadField = () => {
        let fields = downloadField.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields['userName']) {
            formIsValid = false;
            errors['userName'] = MULTI_LANG.POPUP_VALIDATION_MESSAGE_USERNAME;
        }
        if (!fields['procReason']) {
            formIsValid = false;
            errors['procReason'] = MULTI_LANG.POPUP_VALIDATION_MESSAGE_REASON;
        }

        setDownloadField({...downloadField,
            errors: errors
        })
        return formIsValid;
    }

    const closePopup = () => {
        onOpen(false);
    }

    const async_sendProgramLog = (additionalFormParams={}) => {
        let form = {
            PROCEDURE_OR_TABLE_NAME : "PG_ZZ00_USER_OPER_HIST.PR_LO_USER_RPT_EXL_HIST_SAVE",
        }
        form = {...form, ...additionalFormParams }

        commonRequest((result, callbackParam) => {
            let MULTI_LANG = commonLang();
            successModalFunction(MULTI_LANG, MULTI_LANG.POPUP_EMAIL_SEND_SUCCESS);

            onSuccess();
            closePopup();

            if (nullToBlank(apiInfo) !== '') {
                let apiYn = nullToBlank(apiInfo["PRSN_API_YN"]) === '' ? 'N' : apiInfo["PRSN_API_YN"];

                if (apiYn === 'Y') {
                    prsnInfo.FUNC_TYPE = 'DOWNLOAD_REASON';
                    prsnInfo.REASEON = callbackParam.PROC_REASON;

                    personalApiReq(()=>{}, prsnInfo, apiInfo, "POST");
                }
            }
        }, form, false, form);
    }

    return (
        <>
            {/* <Button className="mr-2 btn-icon btn-icon-onlys" color="excel" onClick={handleTogglePopupDownload}>
                <i className="pe-7s-excel"></i>
            </Button> */}
            <Modal isOpen={open} toggle={onOpen} className="modal-dialog-centered modal-s noshadow">
                {/* <ModalHeader toggle={this.toggle}>다운로드</ModalHeader> */}

                <div className="app-inner-layout__header text-white bg-success">
                    <div className="app-page-title">
                        <div className="page-title-wrapper">
                        <div className="page-title-heading">{MULTI_LANG.POPUP_TITLE_DOWNLOAD}</div>

                        <span className="closeBt" onClick={closePopup}>
                            닫기
                        </span>
                        </div>
                    </div>
                </div>

                <ModalBody>
                    <FormGroup row>
                        <Label sm={3} for="userName" style={{padding: '0 0.4rem 0 0.4rem'}}>
                            {MULTI_LANG.POPUP_LABEL_USER_NAME}
                            <div className="badge badge-dot badge-dot-sm badge-danger ml-1 top-5">
                                {MULTI_LANG.FIELD_REQUIRED}
                            </div>
                        </Label>

                        <Col sm={9}>
                            <Input type="text" name="userName" id="userLogGrid__userName" className="required" disabled
                                placeholder={downloadField.errors.userName} value={downloadField.fields.userName} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="last">
                        <Label for="exampleText" sm={3} style={{padding: '0 0.4rem 0 0.4rem'}}>
                            {MULTI_LANG.POPUP_LABEL_REASON}
                            <div className="badge badge-dot badge-dot-sm badge-danger ml-1 top-5">
                                {MULTI_LANG.FIELD_REQUIRED}
                            </div>
                        </Label>

                        <Col sm={9}>
                            {/* <Input type="textarea" name="procReason" id="userLogGrid__procReason" className="required" innerRef={procReasonInputEl} */}
                            <Input type="textarea" name="procReason" id="userLogGrid__procReason" className="required"
                                placeholder={downloadField.errors.procReason} value={downloadField.fields.procReason} onChange={handleChangeDownloadField("procReason")}/>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="text-center">
                    <Button color="link" onClick={closePopup} className="pl-4 pr-4">
                        {commonLang().BUTTON_CANCEL}
                    </Button>
                    <Button color="primary" onClick={handleExportExcel} className="pl-4 pr-4">
                        {commonLang().BUTTON_SAVE}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
});

export {DownloadReasonPopup, GridExcelDownloadButton};

const addCellClass = (def) => {
    if ( def.cellClass === undefined ) {
        return { ...def,
            cellClass: [ 'defaultFont' ]
        }
    } else {
        return { ...def,
            ...( (typeof def.cellClass === "string") )
                ?{ cellClass: [ 'defaultFont', def.cellClass ] }
                :{ cellClass: [ 'defaultFont', ...def.cellClass ] }
        }
    }
}

export const exportExcel = (gridApi, pageName='', addProperty={}, ) => () => {
    const defaultName = {
        fileName: pageName + "_" + dateFormat(new Date(), "yymmdd"),
        sheetName: pageName,
        exportMode: "xlsx",
    };
    const columnKeys = gridApi.columnController.columnApi.getAllDisplayedColumns()
                                .filter(v => (v.colDef.excelExportable===false?false:true) )
                                .filter(v => (v.colId !== 'MODIFY' && v.colId !== 'CANCEL') )
                                .map(v => v.colId);
    const params = {
        ...defaultName,
        ...addProperty,
        customFooter: [
            [{
                styleId: 'footer',
                data: {
                type: 'String',
                value: '',
                },
                mergeAcross: columnKeys.length - 1,
            },],
        ],
        columnGroups: true,
        columnKeys: columnKeys,

        processCellCallback: (params) => {
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'jh-excel-date'
                    || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('jh-excel-date'))  {
                if (nullToBlank(params.value) === '') {
                    return ' ';
                } else {
                    const year = params.value.slice(0,4);
                    const month = params.value.slice(4,6);
                    const date = params.value.slice(6,8);

                    return `${year}-${month}-${date}`;
                }
            }

            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-date'
                    || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-date'))  {
                return params.value;
            }
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-date-iso'
                    || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-date-iso'))  {
                const date = new Date(params.value);
                const tempDate = dateFormat(date, 'yyyymmdd');
                return tempDate
            }
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-date-time'
                    || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-date-time'))  {
                const year = params.value.slice(0,4);
                const month = params.value.slice(4,6);
                const date = params.value.slice(6,8);
                const time = params.value.slice(8,14);
                const tempDate = `${year}/${month}/${date} ${time.slice(0,2)}:${time.slice(2,4)}:${time.slice(4,6)}`;
                return new Date(tempDate).toISOString();
            }
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-date-time-iso'
                    || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-date-time-iso'))  {
                return new Date(params.value.replace("+00:00", "-09:00")).toISOString();
            }
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-array-v1'
                || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-array-v1'))  {
                    return joinFreeChannelByString(params.value);
            }
            if (params.column.colDef.hasOwnProperty('cellClass') && params.column.colDef.cellClass === 'excel-array-v2'
                || typeof(params.column.colDef.cellClass) === 'object' && params.column.colDef.cellClass.includes('excel-array-v2'))  {
                    return sendTypeFormatter(params);
            }
            

            // cellClass: (params) => {
            //     debugger;
            //     const classNames = [];
            //     if (params.node.lastChild) {
            //         classNames.push('footer');
            //     }

            //     return classNames;
            // }
            // let text = '';
            // if (params.column.colDef.hasOwnProperty('cellRenderer')) {

            //     const cellRendererInstance = params.api.getCellRendererInstances({columns: [params.column.colId], rowNodes: [ params.node ]})[0];

            //     if (!cellRendererInstance.getGui()) text = '';
            //     else text = cellRendererInstance.getGui().innerText

            // }
            // // else if (params.column.colDef.valueFormatter) {
            // //     text = params.column.colDef.valueFormatter(params);

            // // }
            // else {
            //     text = params.value;

            // }
            if(nullToBlank(params.column.colDef.codeName) !== ""){
                return params.node.data[params.column.colDef.codeName];
            }
            if (params.column.colDef.cellClass.length > 1 && typeof params.value === "string") {
                if (params.column.colDef.cellClass[1] === "excel-jh-origin") {
                    return params.value.replace(/(<br>|<br\/>|<br \/>|<BR>|<BR\/>|<BR \/>)/g, '\r\n');
                }
            }
            if(typeof params.value === "string"){
                //return params.value.replace(/(<br>|<br\/>|<br \/>|<BR>|<BR\/>|<BR \/>)/g, '\r\n');
                let returnText = params.value.replace(/(<br>|<br\/>|<br \/>|<BR>|<BR\/>|<BR \/>)/g, '\r\n');
                return returnText.replace(/(<([^>]+)>)/ig,"");
            }
            return params.value;
        },

        processHeaderCallback : (params) =>{
        
            if(params.column.colDef.hasOwnProperty('cellClass') && typeof(params.column.colDef.cellClass) === 'object'
                && params.column.colDef.cellClass.includes('excel-ch-origin')){

                let returnHeaderName = params.column.getColDef().headerName



                return returnHeaderName.split("|::|")[6] === "null" ? returnHeaderName.split("|::|")[0] : returnHeaderName.split("|::|")[6];
            }

            return params.column.getColDef().headerName;
        }

    };

    //[""0""].parentComponent.eGridDiv



    ////// 엑셀 클릭시 컬럼이 변경되면서 사이즈 조절이 플린다. //////
    // const cellStyles = gridApi.columnController.columnDefs.map( def =>
    //     {
    //         if (def.children) {
    //             def.children = def.children.map( defChildren => {
    //                 return addCellClass(defChildren);
    //             });
    //         }

    //         return addCellClass(def);
    //     }
    // );

    // gridApi.setColumnDefs( cellStyles );
    // gridApi.exportDataAsExcel({ ...fileName, columnGroups: true });
    //////////////////////////////////////////////////////////////////////////////

    gridApi.exportDataAsExcel(params);
}