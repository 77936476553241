import {nullToBlank} from "../../utils/commUtils";

export const PagesNav = [
    { icon: 'pe-7s-display1', label: 'System Auth', to: '#/app/zz/zz11_0200' },
    { icon: 'pe-7s-display1', label: 'PMS 서비스 코드 등록', to: '#/app/cm/cm03_2100' },
    { icon: 'pe-7s-display1', label: '프로그램 사용이력', to: '#/app/zz/zz20_0200' },
    { icon: 'pe-7s-display1', label: '사용자 로그인 이력', to: '#/app/zz/zz20_0100' },
    { icon: 'pe-7s-display1', label: '프로그램 관리', to: '#/app/zz/zz11_0300' },
    { icon: 'pe-7s-display1', label: '호텔 설정', to: '#/app/zz/zz02_0700' },
    { icon: 'pe-7s-display1', label: '공통 코드 관리', to: '#/app/zz/zz11_0100' },

    { icon: 'pe-7s-display1', label: '로그인', to: '#/login/zz/zz01_0100' },
    { icon: 'pe-7s-display1', label: '대쉬보드', to: '#/app/zz/zz03_0100' },
    { icon: 'pe-7s-display1', label: '검색 필터', to: '#/app/comm/searchFilter' },
    { icon: 'pe-7s-display1', label: '채널 연동 단계 관리', to: '#/app/zz/zz10_0200' },
    { icon: 'pe-7s-display1', label: '채널 연동 단계 관리', to: '#/app/zz/zz10_0100' },
];

export const MenuSetting = (menuList) => {
    let menuData = menuList;
    let levelOne = [];
    let levelTwo = {};
    let levelThree = {};

    menuData.map((item, index) => {
        switch(item.LVL){
            case 1 :
                if(item.ISLEAF == 0) {
                    levelOne.push({
                        icon: (nullToBlank(item.ICON_CLASS_CODE) !== "" ? item.ICON_CLASS_CODE : 'pe-7s-folder'),
                        label: item.SYSTEM_MENU_NAME,
                        to : '',
                        content: [],
                        pageNo : item.PAGE_NO,
                        systemMenuNo : item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index
                    });
                }else{
                    levelOne.push({
                        icon: (nullToBlank(item.ICON_CLASS_CODE) !== "" ? item.ICON_CLASS_CODE : 'pe-7s-file'),
                        label: item.SYSTEM_MENU_NAME,
                        to: '#' + item.PAGE_URL,
                        pageNo : item.PAGE_NO,
                        systemMenuNo : item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index,
                    });
                }
                break;
            case 2 :
                if(!levelTwo[item.UPPER_SYSTEM_MENU_NO]){
                    levelTwo[item.UPPER_SYSTEM_MENU_NO] = [];
                }

                if(item.ISLEAF == 0) {
                    levelTwo[item.UPPER_SYSTEM_MENU_NO].push({
                        icon: (nullToBlank(item.ICON_CLASS_CODE) !== "" ? item.ICON_CLASS_CODE : 'pe-7s-folder'),
                        label: item.SYSTEM_MENU_NAME,
                        to : '',
                        content: [],
                        pageNo: item.PAGE_NO,
                        systemMenuNo: item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index
                    });
                }else{
                    levelTwo[item.UPPER_SYSTEM_MENU_NO].push({
                        icon: (nullToBlank(item.ICON_CLASS_CODE) !== "" ? item.ICON_CLASS_CODE : 'pe-7s-file'),
                        label : item.SYSTEM_MENU_NAME,
                        to : '#' + item.PAGE_URL,
                        pageNo : item.PAGE_NO,
                        systemMenuNo : item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index
                    });
                }
                break;
            case 3 :
                if(!levelThree[item.UPPER_SYSTEM_MENU_NO]){
                    levelThree[item.UPPER_SYSTEM_MENU_NO] = [];
                }

                if(item.ISLEAF == 0) {
                    levelThree[item.UPPER_SYSTEM_MENU_NO].push({
                        label: item.SYSTEM_MENU_NAME,
                        content: [],
                        to : '',
                        pageNo: item.PAGE_NO,
                        systemMenuNo: item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index
                    });
                }else{
                    levelThree[item.UPPER_SYSTEM_MENU_NO].push({
                        label : item.SYSTEM_MENU_NAME,
                        to : '#' + item.PAGE_URL,
                        pageNo : item.PAGE_NO,
                        systemMenuNo : item.SYSTEM_MENU_NO,
                        lvl : item.LVL,
                        key : index
                    });
                }
                break;
            default :
                break;
        }
    });

    for(var keyVal in levelTwo){
        levelTwo[keyVal].map((item, index) => {
            if(levelThree[item.systemMenuNo]) {
                levelTwo[keyVal][index].content = levelThree[item.systemMenuNo];
            }
        })
    }

    levelOne.map((item, index) => {
        if(levelTwo[item.systemMenuNo]) {
            levelOne[index].content = levelTwo[item.systemMenuNo];
        }
    });

    return levelOne;
}
