import React from "react";

export const CERT_POPUP_LANG = (formatMessage) => {
    let MULTI_LANG = {
        POPUP_HEADER_CERTIFICATION      : formatMessage({id: "certpopup.title.certification",           defaultMessage: "인증"}),
        POPUP_BUTTON_CLOSE              : formatMessage({id: "certpopup.title.close",                   defaultMessage: "닫기"}),
        INPUT_LABEL_USER_NAME           : formatMessage({id: "certpopup.title.username",                defaultMessage: "사용자 명"}),
        INPUT_LABEL_PASSWORD            : formatMessage({id: "certpopup.title.password",                defaultMessage: "비밀번호"}),
        POPUP_BUTTON_CANCEL             : formatMessage({id: "certpopup.title.cancel",                  defaultMessage: "취소"}),
        POPUP_LABEL_COMPLATE            : formatMessage({id: "certpopup.title.complate",                defaultMessage: "완료되었습니다."}),
        CHECK_MESSAGE_INPUT             : formatMessage({id: "certpopup.title.checkInput",              defaultMessage: "을(를) 입력해 주세요."}),
        POPUP_HEADER_WARNING            : formatMessage({id: "com.popup.warning",                       defaultMessage: "경고"}),
    }

    return MULTI_LANG;
};
