import React, {Component, Fragment} from 'react';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

import PerfectScrollbar from 'react-perfect-scrollbar';

const TimelineEx = () => {
    const [ctxHeight, setCtxHeight] = React.useState(0);

    React.useEffect(() => {
        const viewHeight = window.innerHeight;
        const headerHeight = document.getElementsByClassName('app-header')[0].clientHeight;

        const contentHeight = viewHeight - headerHeight - 250;

        const itemHeight = document.getElementsByClassName('vertical-timeline')[0].clientHeight + 40;

        if(itemHeight > contentHeight){
            setCtxHeight(contentHeight);
        }else{
            setCtxHeight(itemHeight);
        }
    }, []);

    return (
        <Fragment>
            <div className="scroll-area-sm" style={{height:ctxHeight}}>
                <PerfectScrollbar>
                    <div className="p-3">
                        <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column">
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목(영문기준 15자이후…)...</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-danger">긴급</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-light">일반</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <div className="timeline-board-title"><button className="mr-2 btn btn-outline-danger">중요</button>공지사항 제목</div>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </div>
                </PerfectScrollbar>
            </div>
        </Fragment>
    )
}

export default TimelineEx;