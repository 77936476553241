import React from "react";

export const SEARCHFILTER_LANG = (formatMessage) => {
    let MULTI_LANG = {
        BUTTON_TITLE_SEARCH                          : formatMessage({id: "searchFilter.button.search", defaultMessage: "검색"}),
        BUTTON_TITLE_INIT                            : formatMessage({id: "searchFilter.button.init", defaultMessage: "초기화"}),
        LABEL_TITLE_CONDITION                        : formatMessage({id: "searchFilter.title.condition", defaultMessage: "검색조건"}),
        LABEL_TITLE_CONDITIONADD                     : formatMessage({id: "searchFilter.title.conditionAdd", defaultMessage: "조건추가"}),
        LABEL_TITLE_ALL                              : formatMessage({id: "searchFilter.title.all", defaultMessage: "전체"}),
    }
    
    return MULTI_LANG;
};