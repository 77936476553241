import React from "react";

export const PAGINATION_LANG = (formatMessage) => {
    let MULTI_LANG = {
        ITEMS_PER_PAGE                : formatMessage({id: "pagination.etc.items_per_page", defaultMessage: "/ 쪽"}),
        JUMP_TO                       : formatMessage({id: "pagination.etc.jump_to", defaultMessage: "이동하기"}),
        JUMP_TO_CONFIRM               : formatMessage({id: "pagination.etc.jump_to_confirm", defaultMessage: "확인하다"}),
        PAGE                          : formatMessage({id: "pagination.etc.page", defaultMessage: " "}),
        PREV_PAGE                     : formatMessage({id: "pagination.etc.prev_page", defaultMessage: "이전 페이지"}),
        NEXT_PAGE                     : formatMessage({id: "pagination.etc.next_page", defaultMessage: "다음 페이지"}),
        PREV_5                        : formatMessage({id: "pagination.etc.prev_5", defaultMessage: "이전 5 페이지"}),
        NEXT_5                        : formatMessage({id: "pagination.etc.next_5", defaultMessage: "다음 5 페이지"}),
        PREV_3                        : formatMessage({id: "pagination.etc.prev_3", defaultMessage: "이전 3 페이지"}),
        NEXT_3                        : formatMessage({id: "pagination.etc.next_3", defaultMessage: "다음 3 페이지"}),
        PAGEOF                        : formatMessage({id: "pagination.etc.of", defaultMessage: "of"}),
    }   
    
    return MULTI_LANG;
};