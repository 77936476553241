import React, {Fragment} from 'react';

import {Tree} from "antd";
import '../../assets/antd/antdTree.css';
import {ModalPopup} from '../Popup/ModalPopup';
import {warningModalFunction} from "../../utils/commAxios";
import { commonLang } from '../../utils/commonLang';

const { TreeNode } = Tree;

const TreeTag = (props) => {
    const { treeData, setTreeData, expandedList, setExpandedList, dragActive, blockActive, checkActive, checkKey, expandHandler, checkHandler, selectHandler, dragEnterHandler, dropHandler, alertMsg, rootMenu, initData=[], warningMsg="" } = props;
    const [checkKeys, setCheckKeys] = React.useState(checkKey);
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [nodeInfo] = React.useState({ dropInfo : "" })

    const loop = data => {
        return (
            data.map((item) => {
                if(item.children && item.children.length) {
                    return (
                        <TreeNode key={item.key} title={item.title}>
                            {loop(item.children)}
                        </TreeNode>
                    );
                }

                return (<TreeNode key={item.key} title={item.title} />);
            })
        )
    }

    const onDragEnter = info => {
        if(typeof dragEnterHandler == "function"){
            dragEnterHandler(info);
        }
    }

    const onDrop = info => {
        if (alertMsg) {
            nodeInfo.dropInfo = info;
            setIsAlertOpen(!isAlertOpen);
        } else {
            dropExec(info);
        }
    }

    const dropExec = info => {
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        let tempParentKey = "";
        let dropParentKey = "";

        const subLoop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key == dragKey) {
                    dropParentKey = tempParentKey;
                }

                if(item.key == key){
                    return callback(item, index, arr);
                }

                if(item.children){
                    if (item.children.length > 0) {
                        tempParentKey = item.key;
                    }

                    return subLoop(item.children, key, callback);
                }
            });
        };

        const data = [...treeData];
        let dragObj;

        subLoop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if(!info.dropToGap) {
            subLoop(data, dropKey, item => {
                item.children = item.children || [];
                item.children.splice(0, 0, dragObj);
            })
        }else if(
            (info.node.props.children || []).length > 0 &&
             info.node.props.expanded &&
             dropPosition === 1 ) {
            subLoop(data, dropKey, item => {
                item.children = item.children || [];
                item.children.unshift(dragObj);
            });
        } else {
            let ar;
            let i;
            subLoop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });

            if(dropPosition === -1){
                ar.splice(i, 0, dragObj);
            }else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        tempParentKey = "";
        dropParentKey = "";

        subLoop(data, dragKey, item => {
            if (dropParentKey === "") {
                if (rootMenu === "") {
                    item.parent = dropParentKey;
                } else {
                    item.parent = rootMenu;
                }
            } else {
                item.parent = dropParentKey;
            }
        });

        setTreeData(data);

        if(typeof dropHandler == "function"){
            dropHandler(info, data);
        }
    }

    const onExpand = expandedKeys => {
        setExpandedList(expandedKeys);

        if(typeof expandHandler == "function"){
            expandHandler(expandedKeys);
        }
    }

    const onCheck = checkedKeys => {
        setCheckKeys(checkedKeys);
        if(typeof checkHandler == "function"){
            checkHandler(checkedKeys);
        }
    }

    const onSelect = (selectedKeys, info) => {
        if(typeof selectHandler == "function"){
            selectHandler(selectedKeys, info);
        }
    }

    const alertYesHandler = () => {
        setIsAlertOpen(!isAlertOpen);
         
        const find = initData.findIndex(child => {
            return child.SYSTEM_MENU_NO == nodeInfo.dropInfo.node.props.eventKey;
        });

        if (find > -1 && nodeInfo.dropInfo.dropToGap == false && (initData[find].SYSTEM_MENU_TYPE_CODE == 1 || initData[find].SYSTEM_MENU_TYPE_CODE == 2 || initData[find].SYSTEM_MENU_TYPE_CODE == 3)) {
            setTimeout(() => {
                warningModalFunction(commonLang(), warningMsg);
            }, 300);
            return;
        }
        
        dropExec(nodeInfo.dropInfo);
    }

    const buttonList = [
        {buttonName:'OK', buttonFunc:() => {alertYesHandler()}, buttonStyle:{
            variant: "contained",
            color: "primary",
            size: "large"}},
        {buttonName:'Cancel', buttonFunc:() => {setIsAlertOpen(!isAlertOpen);}, buttonStyle:{
            variant: "contained",
            color: "secondary",
            size: "large"}}
    ];

    return (
        <Fragment>
            <Tree
                className="draggable-tree"
                expandedKeys={expandedList}
                draggable={dragActive}
                blockNode={blockActive}
                checkable={checkActive}
                checkedKeys={checkKeys}
                onDragEnter={onDragEnter}
                onDrop={onDrop}
                onExpand={onExpand}
                onCheck={onCheck}
                onSelect={onSelect}
                {...props}
            >
                {loop(treeData)}
            </Tree>
            <ModalPopup
                openState={isAlertOpen}
                setOpenState={setIsAlertOpen}
                buttonList={buttonList}
                title={"Alarm"}
                content={alertMsg}
            />
        </Fragment>
    );
}

const flatDataFromTree = (data, treeVal = [], upper = "") => {
    data.forEach((item, index, arr) => {
        item.parent = upper;
        treeVal.push(item);

        if(item.children){
            flatDataFromTree(item.children, treeVal, item.key);
        }
    });

    return treeVal;
}

const treeDataFromFlat = (data, rootKey=null) => {
    if (data.length < 1) {
        return [];
    }

    var childrenToParents = {};

    data.forEach(function (child) {
        var parentKey = child.parent;

        if (parentKey in childrenToParents) {
          childrenToParents[parentKey].push(child);
        } else {
          childrenToParents[parentKey] = [child];
        }
    });

    const trav = (parent) => {
        const parentKey = parent.key;

        if (parentKey in childrenToParents) {
            return _objectSpread({}, parent, {
                children: childrenToParents[parentKey].map(function (child) {
                  return trav(child);
                })
            });
        }

        return _objectSpread({}, parent);
    }

    return childrenToParents[rootKey].map(child => {
        return trav(child);
    })
}

function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);

        if (typeof Object.getOwnPropertySymbols === 'function') {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }

        ownKeys.forEach(function (key) {
            _defineProperty(target, key, source[key]);
        });
    }

    return target;
}

function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
      obj[key] = value;
    }

    return obj;
}

export {TreeTag, flatDataFromTree, treeDataFromFlat}
