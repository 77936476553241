import React from "react";

export const DOWNLOAD_REASON_POPUP_LANG = (formatMessage) => {
    let MULTI_LANG = {
        POPUP_ALERT_NO_GRID_DATA                                    : formatMessage({id: "downloadReasonPopup.alert.noGridData", defaultMessage: "그리드안에 데이터가 존재하지 않습니다."}),
        POPUP_MESSAGE_MODIFYDATA                                    : formatMessage({id: "downloadReasonPopup.alert.modifyData", defaultMessage: "변경된 데이터가 있습니다. 저장 후 이용해 주십시오."}),
        POPUP_TITLE_DOWNLOAD                                        : formatMessage({id: "downloadReasonPopup.alert.popupTitleDownload", defaultMessage: "다운로드"}),
        POPUP_LABEL_USER_NAME                                       : formatMessage({id: "downloadReasonPopup.alert.popupLabelUserName", defaultMessage: "사용자명"}),
        POPUP_LABEL_REASON                                          : formatMessage({id: "downloadReasonPopup.alert.popupLabelReason", defaultMessage: "사유"}),
        POPUP_VALIDATION_MESSAGE_REASON                             : formatMessage({id: "downloadReasonPopup.alert.popupValidationReason", defaultMessage: "반드시 사유를 입력받아야 합니다."}),
        POPUP_VALIDATION_MESSAGE_USERNAME                           : formatMessage({id: "downloadReasonPopup.alert.popupValidationUserName", defaultMessage: "사용자 이름이 비어 있습니다"}),
        FIELD_REQUIRED                                              : formatMessage({id: "downloadReasonPopup.alert.fieldRequired", defaultMessage: "필수 값"}),
    }
    return MULTI_LANG;
};
