import React from "react";
import ReactDOM from 'react-dom';

import $ from 'jquery';
import message_ko from '../locale/message_ko';       // 한국어
import message_en from '../locale/message_en';       // 영어
import message_zh from '../locale/message_zh';       // 중국어
import {selectLangFunc} from "../config/ConnectedIntlProvider";
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";
import {commonLang} from "./commonLang";

const USER_INFO_KEY = 'LOGIN_INFO';
const SESSION_TIME = 720;
const COOKIE_CHANGE_KEY = 'COOKIE_CHANGE_TIME';
export const LOGOUT_TYPE_KEY = 'LOGOUT_TYPE_KEY';

export function pageMoveFunc(props, locationPath) {
    props.history.push(locationPath);
}

/*
$(window).off('beforeunload');
$(window).on('beforeunload', function(e){
    //alert("Close");
    e.returnValue = "Close";
    console.log("close event");
    return "Close";
});
*/

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String (변경할 날짜형식)
 * @description	: dateFormat
 *
 */
Date.prototype.format = function(f) {
    if (!this.valueOf()) return " ";

    let commLang = commonLang();

    let weekName = [commLang.DAY_SUN, commLang.DAY_MON, commLang.DAY_TUE, commLang.DAY_WED, commLang.DAY_THU, commLang.DAY_FRI, commLang.DAY_SAT];
    let d = this;
    let h;

        return f.replace(/(YYYY|YY|yyyy|yy|MM|DD|dd|E|hh|mm|ss|a\/p)/gi, function($1) {
        switch ($1) {
            case "YYYY": case "yyyy": return d.getFullYear();
            case "YY": case "yy": return (d.getFullYear() % 1000).zf(2);
            case "MM": return (d.getMonth() + 1).zf(2);
            case "DD": case "dd": return d.getDate().zf(2);
            case "E": return weekName[d.getDay()];
            case "HH": return d.getHours().zf(2);
            case "hh": return ((h = d.getHours() % 12) ? h : 12).zf(2);
            case "mm": return d.getMinutes().zf(2);
            case "ss": return d.getSeconds().zf(2);
            case "a/p": return d.getHours() < 12 ? "오전" : "오후";
            default: return $1;
        }
    });
};

String.prototype.string = function(len){var s = '', i = 0; while (i++ < len) { s += this; } return s;};
String.prototype.zf = function(len){return "0".string(len - this.length) + this;};
Number.prototype.zf = function(len){return this.toString().zf(len);};

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: Object (id, defaultMessage)
 * @description	: 다국어 변환 함수
 *
 */
export const multiLangMessage = ({id, defaultMessage}) => {
    const selectLang = selectLangFunc();

    switch(selectLang){
        case 'ko' :
            return (message_ko[""+ id]) ? message_ko[""+ id] : defaultMessage;
        case 'en' :
            return (message_en[""+ id]) ? message_en[""+ id] : defaultMessage;
        case 'zh' :
            return (message_zh[""+ id]) ? message_zh[""+ id] : defaultMessage;
        default :
            return (message_ko[""+ id]) ? message_ko[""+ id] : defaultMessage;
    }
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String, Array
 * @description	: 다국어 문자 변환 함수
 *                다국어 치환 형식 @(0), @(1)을 replaceArr에 담겨진 문자열로 순서대로 치환
 *
 */
export const multiLangReplace = (message, replaceArr) => {
    replaceArr.forEach((item, index) => {
        message = replaceAll(message, '@('+ index +')', item);
    });

    return message;
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String(원본 메시지), String(변결할 문자), String(변경될 문자)
 * @description	: 특정 문자 전체 치환
 *
 */
export const replaceAll = (message, searchString, replaceString) => {
    return message.split(searchString).join(replaceString);
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String, String
 * @description	: 로컬 스토리지 저장 함수
 *
 */
export const saveLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String, String
 * @description	: 로컬 스토리지 삭제 함수
 *
 */
export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String
 * @description	: 로컬 스토리지 값 가져오기
 *
 */
export const loadLocalStorage = (key) => {
    return localStorage.getItem(key);
}

/**
 *
 * @author		: hoonui Jeong
 *
 * @param		: String
 * @description	: undefined, NULL 값 ''값으로 반환
 *
 */
export const nullToBlank = (message) => {
    message = message + '';

    if(message == 'undefined' || message == 'NULL' || message == 'null'){
        message = '';
    }

    return message.trim();
}


export const setCookie = (name, value) => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + SESSION_TIME);
    //date.setTime(date.getTime() + 1000 * 60 * 30);
    document.cookie = name + '=' + encodeURIComponent(value) + ';expires=' + date.toUTCString() + ';path=/';
};

export const getCookie = (name) => {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

    return value? decodeURIComponent(value[2]) : null;
};

export const getBasicCookie = (name) => {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

    return value? value[2] : null;
};

export const deleteCookie = (name) => {
    let date = new Date();
    document.cookie = name + "= " + "; expires=" + date.toUTCString() + "; path=/";
}

export const initCookie = (name) => {
    setCookie(name, getCookie(name));
}

export const getSessionInfo = () => {
    let sessionInfo = getCookie(USER_INFO_KEY);

    if(nullToBlank(sessionInfo) == ""){
        return "";
    }else{
        return JSON.parse(sessionInfo);
    }
}

export const setCookieUserInfo = (value) => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + SESSION_TIME);
    //date.setTime(date.getTime() + 1000 * 60 * 30);
    document.cookie = USER_INFO_KEY + '=' + encodeURIComponent(value) + ';expires=' + date.toUTCString() + ';path=/';

    let changeTime = new Date().format('YYYYMMDDHHmmss');

    saveLocalStorage(COOKIE_CHANGE_KEY, changeTime);
    removeLocalStorage(LOGOUT_TYPE_KEY);

    return changeTime;
};

export const removeUserCookie = (userLogOut) => {
    let date = new Date();
    document.cookie = USER_INFO_KEY + "= " + "; expires=" + date.toUTCString() + "; path=/";

    removeLocalStorage(COOKIE_CHANGE_KEY);

    if(userLogOut === 'Y'){
        saveLocalStorage(LOGOUT_TYPE_KEY, 'Y');
    }
}

export const lpad = (str, padLen, padStr) => {
    if (padStr.length > padLen) {
        return str;
    }
    str += "";
    padStr += "";
    while (str.length < padLen)
        str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
}

export const rpad = (str, padLen, padStr) => {
    if (padStr.length > padLen) {
        return str + "";
    }
    str += "";
    padStr += "";
    while (str.length < padLen)
        str += padStr;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
}

$.fn.serializeObject = function() { var obj = null; try { if(this[0].tagName && this[0].tagName.toUpperCase() == "FORM" ) { var arr = this.serializeArray(); if(arr){ obj = {}; $.each(arr, function() { obj[this.name] = this.value; }); } } }catch(e) { alert(e.message); }finally {} return obj; }


export const showLoading = () => {
    if(nullToBlank($("#loadingbar").html()) === "") {
        $('body').css('overflow', 'hidden');

        const LoadingTag = () => {
            return (
                <BlockUi tag="div" blocking={true} loader={<Loader active type={"ball-pulse-sync"}/>}
                         style={{
                             position: 'fixed',
                             top: '0',
                             left: '0',
                             overflow: 'hidden',
                             height: '100%',
                             width: '100%',
                             zIndex: '99999'
                         }}
                > </BlockUi>
            )
        }

        ReactDOM.render(<LoadingTag />, document.getElementById('loadingbar'));
    }
}

export const hideLoading = () => {
    if(nullToBlank($("#loadingbar").html()) !== "") {
        $('body').attr('style', '');
        ReactDOM.render('', document.getElementById('loadingbar'));
    }
}

export const setGridHeight = (gridApi, noneButton, isAdd=false ,addHeight=0, headerSize=0, addBottom=0, addHorizontalWidth=0) => {
    if ($(".ag-theme-balham").length < 1) {
        return;
    }

    const TOP_BAR_SIZE = 60;

    const viewHeight = window.innerHeight;

    const contentHeight = document.querySelector(".app-main__inner").clientHeight;
    const gridViewHeight = document.querySelector(".ag-theme-balham").clientHeight;
    const otherAreaHeight = contentHeight - gridViewHeight;
    const gridRealHeight = document.querySelector(".ag-center-cols-clipper").clientHeight
                            + document.querySelector(".ag-header.ag-pivot-off").offsetHeight
                            + document.querySelector(".ag-body-horizontal-scroll").clientHeight//+ 42;
    const blankHeight = viewHeight - contentHeight - TOP_BAR_SIZE;
    const pinTopHeight = document.querySelector(".ag-floating-top").clientHeight;
    const pinBottomHeight = document.querySelector(".ag-floating-bottom").clientHeight;
    const gapHeight = viewHeight - (otherAreaHeight + gridRealHeight + TOP_BAR_SIZE + pinBottomHeight + pinTopHeight);
    const horizontalElement = $(".ag-body-horizontal-scroll-viewport");

    if ($(".ag-horizontal-left-spacer").length > 0) {
        $(".ag-horizontal-left-spacer").css("display",'none');
    }

    if(gapHeight >= -2) {
        let setHeight = gridViewHeight + blankHeight;

        if(noneButton){
            setHeight -= 19;
        }

        if(isAdd) {
            setHeight += addHeight;
        }

        gridApi.setDomLayout("normal");
        $(".ag-theme-balham").parent().height(setHeight);

        horizontalElement.css("position","");
        horizontalElement.css("bottom","");
        horizontalElement.css("width","");
        horizontalElement.css("z-index","");
    } else {
        gridApi.setDomLayout("autoHeight");
        $(".ag-theme-balham").parent().height('');

        const scrollArea = $(".app-container").children('div').children('div')[0];

        if ($(scrollArea).scrollTop() >= scrollArea.scrollHeight - scrollArea.offsetHeight + 16 - addBottom) {
            horizontalElement.css("position","");
            horizontalElement.css("bottom","");
            horizontalElement.css("z-index","");
            horizontalElement.css("width","");
        } else {
            const gridViewWidth = document.querySelector(".ag-theme-balham").clientWidth;

            if (horizontalElement.css("position") != 'fixed') {
                if ($(".fixFooter").length > 0) {
                    horizontalElement.css("position","fixed");
                    horizontalElement.css("bottom","57px");
                    horizontalElement.css("z-index","2");
                    horizontalElement.css("width",gridViewWidth);
                } else {
                    horizontalElement.css("position","fixed");
                    horizontalElement.css("bottom","0px");
                    horizontalElement.css("z-index","2");
                    horizontalElement.css("width",gridViewWidth);
                }
            } else {
                horizontalElement.css("width",gridViewWidth);
            }
        }
    }

    if (headerSize > 0) {
        if ($(".ag-body-horizontal-scroll-container").length > 0) {
            $(".ag-body-horizontal-scroll-container").css("width", headerSize);
        }
    }

    if (addHorizontalWidth > 0) {
        if ($(".ag-body-horizontal-scroll-container").length > 0) {
            const horizontalWidth = Number($(".ag-body-horizontal-scroll-container").css("width").replace("px", ""));
            $(".ag-body-horizontal-scroll-container").css("width", horizontalWidth + addHorizontalWidth);
        }
    }
}

export const dateFormatChange = (dateFormat) => {
    let result = "";

    if(nullToBlank(dateFormat) === "") {
        dateFormat = getSessionInfo().SS_USER_DATE_FORMAT;
    }

    if(nullToBlank(dateFormat) !== "") {
        let upperFormat = dateFormat.toString().toUpperCase();
        if(upperFormat === "YYYY-MM-DD"){
            result = 'yyyy-MM-dd';
        }else if(upperFormat === "DD/MM/YYYY"){
            result = 'dd/MM/yyyy'
        }else if(upperFormat === "MM/DD/YYYY"){
            result = 'MM/dd/yyyy'
        }else{
            result = 'yyyy-MM-dd';
        }
    }

    return result;
}

export const deepCopy = (obj) => {
    let output = {};
    for(let i in obj){
        output[i] = obj[i];
    }

    return output;
}

export const currencyComma = (value) => {
    return Math.floor(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const stringDateFormatChange = (value) => {
    if(typeof value === 'object' && nullToBlank(value) !== ""){
        value = value.value;
    }

    let result = '';
    let dateFormat = getSessionInfo().SS_USER_DATE_FORMAT;

    if(nullToBlank(value) !== "" && value.length === 8){
        let year = value.substring(0, 4);
        let month = value.substring(4, 6);
        let day = value.substring(6, 8);

        let upperFormat = dateFormat.toString().toUpperCase();

        if(upperFormat === "YYYY-MM-DD"){
            result = year +'-'+ month +'-'+ day;
        }else if(upperFormat === "DD/MM/YYYY"){
            result = day +'/'+ month +'/'+ year;
        }else if(upperFormat === "MM/DD/YYYY") {
            result = month +'/'+ day +'/'+ year;
        }else{
            result = year +'-'+ month +'-'+ day;
        }
    }

    return result;
}

export const parseDate = (str) => {
    if(!/^(\d){8}$/.test(str)) return "invalid date";
    let y = str.substr(0,4),
        m = parseInt(str.substr(4,2)) - 1,
        d = str.substr(6,2);
    return new Date(y,m,d);
}

export const digitPointFormat = (value) => {
    let digitPoint = parseInt(getSessionInfo().SS_DECIMAL_POINT_DIGIT);
    let resultValue = parseFloat(String(value));

    try{
        resultValue = resultValue.toFixed([digitPoint]);
    }catch(e){
        resultValue = 0;
    }

    return resultValue;
}

export const numberWithCommas = (value) => {
    if(nullToBlank(value) == ""){
        return value;
    }else if(value == "0"){
        return value;
    }

    value = String(value).replace(/,/g,'');          // ,값 공백처리
    let xArr = [];

    //let x = parseFloat(value).toString();
    let x = value.replace(/[^0-9.]/g,'');   // 입력값이 숫자가 아니면 공백

    if(nullToBlank(x) !== ""){
        xArr = x.split('.');
        x = String(Number(xArr[0]));
    }

    if(xArr.length > 1){
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + xArr[1];
    }else{
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const numberCommaWithDigitPoint = (value) => {
    let result =  parseFloat(value);
    result = digitPointFormat(result);
    result = numberWithCommas(result);

    return result;
}

export const removeComma = (value) => {
    if(nullToBlank(value) !== ""){
        value = String(value).replace(/,/g, '');
    }

    return value;
}

export const phoneNumberFormatting = (value) => {
    value = value.replace(/[^0-9^()+-\s]/g,'');   // 입력값이 숫자가 아니면 공백

    return value;
}

export const xssFilter = (value) => {
    if(nullToBlank(value) === ""){
        return value;
    }

    value = value.replace(/[<]/g, "&lt;").replace(/[>]/g, "&gt;");
    value = value.replace(/[']/g, "&quot;").replace(/["]/g, '&#39;');

    return value;
}

export const minusNumberWithCommas = (value) => {
    if(nullToBlank(value) === ""){
        return value;
    }else if(value === 0){
        return value;
    }else if (value === '+') {
        return value;
    }else if (value === '-') {
        return value;
    }

    value = (value + "").replace(/,/g,'');          // ,값 공백처리
    let xArr = [];

    //let x = parseFloat(value).toString();
    let x = value.replace(/[^0-9.+-]/g,'');   // 입력값이 숫자가 아니면 공백

    if(nullToBlank(x) !== ""){
        xArr = x.split('.');
        x = String(Number(xArr[0]));
    }

    if(xArr.length > 1){
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + xArr[1];
    }else{
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const dates = {
    convert:function(d) {
        // Converts the date in d to a date-object. The input can be:
        //   a date object: returned without modification
        //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
        //   a number     : Interpreted as number of milliseconds
        //                  since 1 Jan 1970 (a timestamp)
        //   a string     : Any format supported by the javascript engine, like
        //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
        //  an object     : Interpreted as an object with year, month and date
        //                  attributes.  **NOTE** month is 0-11.
        return (
            d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0],d[1],d[2]) :
                    d.constructor === Number ? new Date(d) :
                        d.constructor === String ? new Date(d) :
                            typeof d === "object" ? new Date(d.year,d.month,d.date) :
                                NaN
        );
    },
    compare:function(a,b) {
        // Compare two dates (could be of any type supported by the convert
        // function above) and returns:
        //  -1 : if a < b
        //   0 : if a = b
        //   1 : if a > b
        // NaN : if a or b is an illegal date
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(a=this.convert(a).valueOf()) &&
            isFinite(b=this.convert(b).valueOf()) ?
                (a>b)-(a<b) :
                NaN
        );
    },
    inRange:function(d,start,end) {
        // Checks if date in d is between dates in start and end.
        // Returns a boolean or NaN:
        //    true  : if d is between start and end (inclusive)
        //    false : if d is before start or after end
        //    NaN   : if one or more of the dates is illegal.
        // NOTE: The code inside isFinite does an assignment (=).
        return (
            isFinite(d=this.convert(d).valueOf()) &&
            isFinite(start=this.convert(start).valueOf()) &&
            isFinite(end=this.convert(end).valueOf()) ?
                start <= d && d <= end :
                NaN
        );
    }
}

export const moveFocusElement = (id, clickExec = false) => {
    let moveTarget = document.getElementById(id);

    if (moveTarget !== null) {
        if (clickExec) {
            moveTarget.click();
        } else {
            moveTarget.focus();
        }
    }
}
